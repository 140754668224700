import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import ProductRow from "./ProductRow";
import SearchHeader from "../header/SearchHeader";
import teamImage from '../../images/MicrosoftTeams-image.png';
import { FaRegCopy, FaCircleCheck } from 'react-icons/fa6';
// All other imports
// import { getDocument } from 'pdfjs-dist/webpack';
// import pdfjsLib from 'pdfjs-dist/build/pdf';
import {
  Alert,
  Toast,
  ToastContainer as ToastContainerBootstrap,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Modal, Table, Row, Col, Card } from 'react-bootstrap';
import TxModal from "../admin/TxModal";
import AddToWishlistPic from "./AddToWishlistPic";
import BidModal from "./BidModal";
import axios from "axios";
import "../../css/style.css";
import "../../css/search.css";
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
// pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';

const ProductDetail = (props) => {
  const [images, setImages] = useState(["1.jpg", "2.jpg", "3.jpg", "4.jpg"]);
  const [image, setImage] = useState(images[0]);
  const [files, setFiles] = useState([]);
  const [name, setName] = useState("Sample Product");
  const [details, setDetails] = useState({});
  const [favourites, setFavourites] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [show, setShow] = useState(false);
  const [bids, setBids] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [wishListCount, setWishListCount] = useState(0);
  const [wishListTotal, setWishListTotal] = useState(0);
  const [purchaseMethod, setPurchaseMethod] = useState(null);
  const [showAddedToCartToast, setShowAddedToCartToast] = useState(false);
  const [showAddedToWishListToast, setShowAddedToWishListToast] =
    useState(false);
  const [flag, setFlag] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [cartPriceTotal, setCartPriceTotal] = useState(0);
  const [permission, setPermission] = useState(null);
  const [permissionChat, setPermissionChat] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showTxModal, setShowTxModal] = useState(false);
  const [txModalType, setTxModalType] = useState();
  const [txTitle, setTxTitle] = useState("");
  const [txMsg, setTxMsg] = useState("");
  const [txData, setTxData] = useState();
  const [showModalRating, setShowModalRating] = useState(false);
  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [feedbacks, setFeedbacks] = useState([

  ]);
  const [docName, setDocName] = useState([]);
  const [loginFlag, setLoginFlag] = useState(true);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [showAddToWishlist, setShowAddToWishlist] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showQuantityInput, setShowQuantityInput] = useState(false);
  const [quantityPic, setQuantityPic] = useState(0);
  const [itemsInside, setItemsInside] = useState([]);
  const [showBidModal, setShowBidModal] = useState(false);
  const [product, setProduct] = useState();
  const location = useLocation();
  const [state, setState] = useState(null);
  const params = useParams();
  const { type, id } = params;
  // const productState = location.state?.product;
  // const type = location.state?.type;
  const navigate = useNavigate();

  const [showShareModal, setShowShareModal] = useState(false);
  const [isShareLinkCopied, setIsShareLinkCopied] = useState(false);

  useEffect(() =>
  {
    const fetchItem = async () =>
    {
      const response = await axios.post('/get_item_by_id', { itemId: id });
      const responseData = response?.data;

      if (responseData)
      {
        const itemData = responseData || {};

        if (!itemData["Seller Name"])
        {
          itemData["Seller Name"] = "*******";
        }
        
        setState(itemData);
      }
    }

    const fetchLot = async () =>
    {
      const response = await axios.post('/get_lot_by_id', { lotId: id });
      const responseData = response?.data;

      if (responseData)
      {
        const lotData = responseData || {};

        if (!lotData["Seller Name"])
        {
          lotData["Seller Name"] = "*******";
        }
        
        setState(lotData);
      }
    }

    if (type === "product")
    {
      fetchItem();
    }
    else if (type === "lot")
    {
      fetchLot();
    }
  }, [type, id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Determine payload based on type
        let payload = {};
        if (type === 'product' && state?.itemId) {
          payload = { type: type, itemId: state?.itemId };
        } else if (type === 'lot' && state?.lotId) {
          payload = { type: type, lotId: state?.lotId };
        } else {
          return;  // Don't make request if neither condition is met
        }

        const response = await axios.post('/get_rating', payload);
        if (response.status === 200) {
          console.log("FEEDBACK", response.data);
          setFeedbacks(response.data.ratings);
          setRating(response.data.averageRating);
        }
      } catch (error) {
        console.error("Error fetching ratings:", error);
      }
    };

    fetchData();
  }, [type, state]);
  const fetchRating = async () => {
    try {
      // Determine payload based on type
      let payload = {};
      if (type === 'product' && state?.itemId) {
        payload = { type: type, itemId: state?.itemId };
      } else if (type === 'lot' && state?.lotId) {
        payload = { type: type, lotId: state?.lotId };
      } else {
        return;  // Don't make request if neither condition is met
      }

      const response = await axios.post('/get_rating', payload);
      if (response.status === 200) {
        console.log("FEEDBACK", response.data);
        setFeedbacks(response.data.ratings);
        setRating(response.data.averageRating);
      }
    } catch (error) {
      console.error("Error fetching ratings:", error);
    }
  };

  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const response = await axios.get('/pdf-view-permission', {
          params: { userId: currentUser.id, itemId: state?.itemId }
        });
        setPermission(response.data.attachmentView);
        console.log("PERMISSIOn", response.data.attachmentView)
      } catch (error) {
        console.error('Error fetching permission:', error);
        // Handle error appropriately
      }
      try {
        const response = await axios.get('/check_chat_allowed', {
          params: { user_id: currentUser.id }
        });
        setPermissionChat(response.data.chat_allowed);
        console.log("PERMISSIOn", response.data.chat_allowed)
      } catch (error) {
        console.error('Error fetching permission:', error);
        // Handle error appropriately
      }
    };

    if (currentUser && currentUser.id) {
      fetchPermission();
    }
  }, []);


  useEffect(() => {
    console.log("STATE", state);
    if (state) {
      if (type === "product") {
        const imageExtensions = [".jpg", ".png", ".jpeg"];
        const images = state?.extendedFieldValues
          ?.split(",")
          .filter((value) =>
            imageExtensions.some((ext) => value.includes(ext))
          ) || [];
        setImages([state?.itemImage, ...images]);
        console.log("IMAGES", images)
        const fileExtensions = [".pdf"];
        const files = state?.extendedFieldValues
          ?.split(",")
          .filter((value) => fileExtensions.some((ext) => value.includes(ext)));
        setFiles(files);

        const extendedFieldNameArray = state?.extendedFieldName?.split(",");
        const extendedFieldValuesArray = state?.extendedFieldValues?.split(",");
        const extendedDocArray = state?.extendedDoc
          ?.split(",")
          .map((value) => value.trim());
        setDocName(extendedDocArray);
        console.log("extendedfieldname", state?.extendedFieldName, extendedFieldNameArray)
        for (let i = 0; i < extendedFieldNameArray?.length; i++) {
          state[extendedFieldNameArray[i]] = extendedFieldValuesArray[i];
        }
        console.log(state);
        setImage(state?.itemImage);
        setName(state?.itemName);
        setPurchaseMethod(state?.itemPackaging);
        setQuantity(parseInt(state?.itemMinQuantity));
        setQuantityPic(parseInt(state?.itemMinQuantity));
        const newDetails = { ...details, ...state };
        delete newDetails.itemImage;
        delete newDetails.averageRating;
        delete newDetails.itemFile;
        delete newDetails.itemIndustry;
        delete newDetails.images;
        delete newDetails.document;
        delete newDetails.extendedFieldName;
        delete newDetails.extendedFieldValues;
        delete newDetails.itemId;
        delete newDetails.itemFileName;
        delete newDetails.extendedDoc;
        delete newDetails.sellerId;
        delete newDetails.isDeleted;
        delete newDetails.published;
        delete newDetails.updatedOn;
        delete newDetails.itemDesc;
        delete newDetails.itemQuantity;
        delete newDetails.itemExpiry;
        delete newDetails.itemPublishedQuantity;
        delete newDetails.bidChoice;
        delete newDetails.itemMinQuantity;
        delete newDetails.bidExpiry;
        delete newDetails.lotQuantity;
        delete newDetails.Industries;
        delete newDetails.quantitySold;
        delete newDetails.lat;
        delete newDetails.lng;

        // Create a copy of newDetails without undefined values
        console.log(`${process.env.REACT_APP_SERVER_URL}/static/images/${images[0]}`);
        console.log(`${process.env.REACT_APP_SERVER_URL}/static/images/${images[1]}`);
        const filteredDetails = Object.fromEntries(
          Object.entries(newDetails).filter(([key, value]) => value !== "undefined")
        );

        const modifiedDetails = {};
        Object.keys(filteredDetails).forEach((key) => {
          if (key.includes("Price")) {
            modifiedDetails[key.replace(/^item/, "")] = `${filteredDetails[key]} ${(currentUser && currentUser.currency) ? currentUser.currency : 'USD'}`;
          } else {
            modifiedDetails[key.replace(/^item/, "")] = filteredDetails[key];
          }
        });



        setDetails(modifiedDetails);
      }
      else if (type === "lot") {
        const imageExtensions = [".jpg", ".png", ".jpeg"];
        const images = state?.extendedFieldValues
          ?.split(",")
          .filter((value) =>
            imageExtensions.some((ext) => value.includes(ext))
          ) || [];
        setImages([state?.lotImage, ...images]);
        const fileExtensions = [".pdf"];
        const files = state?.extendedFieldValues
          ?.split(",")
          .filter((value) => fileExtensions.some((ext) => value.includes(ext)));
        setFiles(files);
        console.log("IMAGES", images)

        const extendedFieldNameArray = state?.extendedFieldName?.split(",");
        const extendedFieldValuesArray = state?.extendedFieldValues?.split(",");
        const extendedDocArray = state?.extendedDoc
          ?.split(",")
          .map((value) => value.trim());
        setDocName(extendedDocArray);
        console.log('file array', files);
        for (let i = 0; i < extendedFieldNameArray.length; i++) {
          state[extendedFieldNameArray[i]] = extendedFieldValuesArray[i];
        }
        console.log(state);
        setImage(state?.lotImage);
        const lotItemsArray = state?.lotItems?.split(",");
        const lotItemsQuantityArray = state?.lotItemsQuantity?.split(",");
        if (lotItemsArray.length !== lotItemsQuantityArray.length) {
          console.error("Arrays have different lengths");
        }
        let lotItemsWithQuantity = lotItemsArray.map((item, index) => {
          return { [item]: lotItemsQuantityArray[index] }
        });
        console.log(lotItemsWithQuantity);
        setItemsInside(lotItemsWithQuantity);
        setName(state?.lotName);
        setPurchaseMethod('Lot');
        setQuantity(1);
        setQuantityPic(1);
        const newDetails = { ...details, ...state };
        delete newDetails.lotImage;
        delete newDetails.itemFile;
        delete newDetails.images;
        delete newDetails.document;
        delete newDetails.extendedFieldName;
        delete newDetails.extendedFieldValues;
        delete newDetails.itemId;
        delete newDetails.lotItems;
        delete newDetails.averageRating;
        delete newDetails.lotItemsQuantity;
        delete newDetails.lotName;
        delete newDetails.extendedDoc;
        delete newDetails.sellerId;
        delete newDetails.lotDesc;
        delete newDetails.isSold;
        delete newDetails.bidChoice;
        delete newDetails.itemMinQuantity;
        delete newDetails.lat;
        delete newDetails.lng;
        delete newDetails.Industries;
        const modifiedDetails = {};
        Object.keys(newDetails).forEach((key) => {
          if (key.includes("Price")) {
            modifiedDetails[key.replace(/^lot/, "")] = `${newDetails[key]} ${(currentUser && currentUser.currency) ? currentUser.currency : 'USD'}`;
          } else {
            modifiedDetails[key.replace(/^lot/, "")] = newDetails[key];
          }
        });

        console.log("IMAGES", images)
        setDetails(modifiedDetails);

      }
    } else {
      setImages(["1.jpg", "2.jpg", "3.jpg", "4.jpg"]);
      setImage("1.jpg");
      setName("Sample Product");
    }
  }, [state]);

  const openRatingModal = async (product) => {
    if (!currentUser || !currentUser.id) {
      setLoginFlag(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMsg(`Login before rating this product`);
      //setTxData(tableMeta.rowData[0]);
      setShowTxModal(true);
    }
    else {
      try {
        let response;
        if (type === 'lot') {
          response = await axios.post("/check_buyer", {
            userId: currentUser.id,
            lotId: state?.lotId,
            type: 'lot'
          });
          console.log(response);
          if (response.status === 202) {
            toast.error(response.data.data)
          }
          else {
            setCurrentProduct(product);
            setShowModalRating(true);
          }

        }
        else {
          response = await axios.post("/check_buyer", {
            userId: currentUser.id,
            itemId: state?.itemId,
            type: 'item'
          });
          if (response.status === 202) {
            toast.error(response.data.data)
          }
          else {
            setCurrentProduct(product);
            setShowModalRating(true);
          }
        }



      } catch (error) {
        console.log(error);
        toast.error(error.data);
      }
    }


  };

  const handleRatingSubmit = async () => {
    // TODO: API call to /give_rating with { product: currentProduct, rating, feedback }
    let response;
    if (type === 'product') {
      response = await axios.post("/add_rating", {
        userId: currentUser.id,
        itemId: state?.itemId,
        rate: rating,
        message: feedback,
        type: 'item'
      });
      if (response) {
        fetchRating();
        toast.success("Rating and Feedback submitted");

      }

    }
    else {
      response = await axios.post("/add_rating", {
        userId: currentUser.id,
        lotId: state?.lotId,
        rate: rating,
        message: feedback,
        type: 'lot'
      });
      if (response) {
        fetchRating();
        toast.success("Rating and Feedback submitted")
      }

    }

    setShowModalRating(false);
  };
  const handleClear = async () => {
    setTxModalType(1);
    setTxTitle(`Clear Confirmation`);
    setTxMsg(
      `Can not add item of different companies in the cart. Do you want to clear the cart?`
    );
    //setTxData(tableMeta.rowData[0]);
    setShowTxModal(true);
  };
  const reveal = async () => {
    if (loginFlag === true) {
      axios
        .post("/clear_cart", { userId: currentUser.id })
        .then((response) => {
          setShowTxModal(false);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate("/admin/login", {
        state: { from: location, additional: state, type: type },
      });
    }
  };

  const incrementQuanity = () => {
    console.log(quantity);
    let newQuan = quantity + 1;
    console.log(state?.itemQuantity);
    console.log(state?.itemMinQuantity);
    //console.log(state?.quantity)
    if (newQuan <= state?.itemPublishedQuantity) {
      setQuantity(quantity + 1);
      setFlag(false);
    } else {
      toast.error("Not enough stock in place");
    }
  };

  const decrementQuanity = () => {
    if (quantity > state?.itemMinQuantity) {
      setQuantity(quantity - 1);
      setFlag(false);
    }
  };
  const handleQuantityChangeItem = (e) => {
    setQuantity(e.target.value);
  };

  const chatWithSeller = async () => {
    if (!currentUser || !currentUser.id) {
      setLoginFlag(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMsg(`Login before chatting with seller`);
      //setTxData(tableMeta.rowData[0]);
      setShowTxModal(true);
    } else {
      if (permissionChat === 'disable'){
        toast.error("Upgrade your subscription to chat with seller")
        return;
      }
      try {
        let response;
        let tabValue; // To hold the tab value

        if (type === 'lot') {
          response = await axios.post("/create_groupchat", {
            senderUserId: currentUser.id,
            lotId: state?.lotId,
            sellerId: state?.sellerId
          });
          const chatId = response.data.chatId;
          if (chatId) {
            tabValue = 'lots'; // Set the tab value for lot
            navigate("/admin/chat-support", { state: { chatId, tab: tabValue } });
          }
        }
        else {
          response = await axios.post("/create_chat", {
            senderUserId: currentUser.id,
            receiverUserId: state?.sellerId,
            itemId: state?.itemId,
            type: 'product'
          });
          const chatId = response.data.chatId;
          if (chatId) {
            tabValue = 'items'; // Set the tab value for product
            navigate("/admin/chat-support", { state: { chatId, tab: tabValue } });
          }
        }
      } catch (error) {
        console.error("Error creating chat:", error);
      }
    }
  };


  const addToCart = () => {
    if (!currentUser) {
      // User is not logged in
      setLoginFlag(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMsg(`Login before adding to cart`);
      setShowTxModal(true);
    } else {
      // User is logged in
      if (quantity < state?.itemMinQuantity) {
        toast.error("Quantity can not be lower than minimum buying quantity");
      }
      else if (state?.itemPublishedQuantity < state?.itemMinQuantity) {
        toast.error("Out of Stock")
      } else {
        const objToSend = {
          userId: currentUser.id,
          price: state?.itemPrice,
          quantity: quantity,
          itemId: state?.itemId,
          type: 'product'
        };
        axios
          .post("/add_to_cart", objToSend)
          .then((response) => {
            if (response.status === 202) {
              handleClear();
            } else if (response.status === 200) {
              setShowAddedToCartToast(true);
            } else if (response.status === 203) {
              toast.error("Quantity exceeds the maximum order quantity");
            }
            else if (response.status === 210) {
              toast.error("Items/lots from the same location can be added, empty your cart before adding this item");
            }
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };


  const handlePromptResponse = (response) => {
    setShowPrompt(false);
    if (response === 1) {
      setShowQuantityInput(true);
    } else if (response === 0) {
      const objToSend = {
        userId: currentUser.id,
        price: state?.itemPrice,
        quantity: state?.itemMinQuantity,
        itemId: state?.itemId,
        type: "product"
      };
      axios
        .post("/add_to_wishlist", objToSend)
        .then((response) => {
          if (response.status === 202) {
            toast.error("Quantity exceeds the available quantity");
          } else if (response.status === 200) {
            setShowAddedToWishListToast(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (response === 3) {
      setShowAddToWishlist(0);
      setShowPrompt(0);
      setShowQuantityInput(false);



    }
  };

  const handleQuantityChange = (e) => {
    if (e.target.value >= state?.itemPublishedQuantity) {
      toast.error("Quantity exceeds the maximum order quantity");
      setQuantityPic(state?.itemPublishedQuantity);
    }
    if (e.target.value <= state?.itemMinQuantity) {
      toast.error("Quantity can not be less than minimum order quantity");
      setQuantityPic(state?.itemMinQuantity);
    } else {
      setQuantityPic(e.target.value);
    }
  };

  const handleSaveQuantity = () => {
    // Save the quantity and add the item to the wishlist.
    console.log("Quantity:", quantityPic);
    if (!quantityPic) {
      toast.error("Enter a quantity");
    } else {
      const objToSend = {
        userId: currentUser.id,
        price: state?.itemPrice,
        quantity: quantityPic,
        itemId: state?.itemId,
        type: "product"
      };
      axios
        .post("/add_to_wishlist", objToSend)
        .then((response) => {
          if (response.status === 202) {
            toast.error("Quantity exceeds the available quantity");
          } else if (response.status === 200) {
            setShowAddedToWishListToast(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setShowQuantityInput(false);
    }
  };

  const handlePicWishlist = () => {
    if (!currentUser || !currentUser.id) {
      setLoginFlag(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMsg(`Login before adding to wishlist`);
      //setTxData(tableMeta.rowData[0]);
      setShowTxModal(true);
    } else {
      if (type === 'lot') {
        addToWishList();

      }
      else {
        setShowAddToWishlist(true);
        setShowPrompt(true);

      }

    }
  };
  const handleBid = () => {
    if (!currentUser) {
      setLoginFlag(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMsg(`Login before bidding`);
      setShowTxModal(true);
    } else {
      const objToSend = {
        userId: currentUser.id,
        price: state?.lotPrice,
        quantity: 1,
        lotId: state?.lotId,
        type: 'lot'
      };
      axios
        .post("/add_to_wishlist", objToSend)
        .then((response) => {
          if (response.status === 202 || response.status === 200) {
            // On successful addition to wishlist, get wishlist item
            axios
              .get("/get_wishlist_item", { params: { userId: currentUser.id, lotId: state?.lotId } })
              .then((response) => {
                if (response.status === 200) {
                  console.log("RESPONSE", response.data)

                  const productData = {
                    lotId: response.data.lotId,
                    wishlistitemId: response.data.wishlistItemId,
                    image: response.data.lotImage,
                    name: response.data.lotName,
                    bidChoice: response.data.bidType,
                    price: response.data.initialPrice,
                    newPrice: response.data.wishlistPrice,
                    quantity: response.data.quantity,
                    status: response.data.status,
                    sellerId: response.data.sellerId,
                    allowed: response.data.allowed
                  };

                  setProduct(productData);
                  setShowBidModal(true);

                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const addToWishList = () => {
    if (!currentUser) {
      setLoginFlag(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMsg(`Login before adding to wishlist`);
      //setTxData(tableMeta.rowData[0]);
      setShowTxModal(true);
    } else {
      if (type === "product") {
        if (quantity < state?.itemMinQuantity) {
          toast.error("Quantity can not be lower than minimum buying quantity")
        }
        else if (state?.itemPublishedQuantity < state?.itemMinQuantity) {
          toast.error("Out of Stock")
        }
        else {
          const objToSend = {
            userId: currentUser.id,
            price: state?.itemPrice,
            quantity: quantity,
            itemId: state?.itemId,
            type: type
          };
          axios
            .post("/add_to_wishlist", objToSend)
            .then((response) => {
              if (response.status === 202) {
                toast.error("Quantity exceeds the available quantity");
              } else if (response.status === 200) {
                setShowAddedToWishListToast(true);
              }
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }



      }
      else if (type === "lot") {
        if (state?.isSold === 1) {
          toast.error("Out of Stock");
          return;
        }
        else {
          const objToSend = {
            userId: currentUser.id,
            price: state?.lotPrice,
            quantity: 1,
            lotId: state?.lotId,
            type: type
          };
          axios
            .post("/add_to_wishlist", objToSend)
            .then((response) => {
              if (response.status === 202) {
                toast.error("Lot already exists in the wishlist");
              } else if (response.status === 200) {
                setShowAddedToWishListToast(true);
              }
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
  };
  const formatDate = (createdOn) => {
    const date = new Date(createdOn);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };


  const handleClick = (selected) => {
    window.scrollTo(0, 0);
    navigate("/product-detail", { state: selected });
  };

  const openProductDocument = (fileName) => {
    // Assuming currentUser and permission are available in the component's scope
    if (!currentUser) {
      toast.error("Please log in to view the document.");
      return;
    }
  
    if (permission === 'disable') {
      toast.error("Upgrade your subscription to view documents.");
      return;
    }
  
    if (Capacitor.isNativePlatform()) {
      Browser.open({ url: fileName });
    } else {
      setFileName(fileName); // Set SAS URL to fileName state
      setShowModal(true);    // Open the modal
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleViewBids = async () => {
    try {
      const response = await axios.get('/get_bids', {
        params: {
          lotId: state?.lotId
        }
      });

      setBids(response.data);
      setShow(true);
    } catch (error) {
      console.error('Failed to fetch bids', error);
    }
  };
  const handleClose = () => {
    setShow(false);
  };

  const isValidImage = (image) => 
  {
    const imageName = image?.split("?")?.[0]?.split("/")?.at(-1) || null;
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
    
    return imageName && typeof imageName === 'string' && imageExtensions.test(imageName);
  }

  const addProductToWishList = () => {
    setShowAddedToWishListToast(true);

    if (flag) {
      let productQuanity = quantity + 1;
      setQuantity(productQuanity);
      setWishListCount(productQuanity);
    } else {
      setFlag(true);

      let productCount = 0;
      setWishListCount(productCount + quantity);
    }

    let tempFavourites = [...favourites];
    tempFavourites.push(name);
    setFavourites(tempFavourites);
  };
  const handleEnter = (bidProd, newPrice) => {
    // let tempProducts = [...products];
    // tempProducts[index].status = "In progress";

    // setSelectedProducts(tempProducts);
    if (parseInt(newPrice) < parseInt(bidProd.price)) {
      toast.error("Enter more than the minimum amount");
    }
    else if (parseInt(newPrice) === 0) {
      toast.error("Bid amount should be more than 0");
    }



    else {
      const data = {
        userId: currentUser.id,
        lotId: bidProd.lotId,
        wishlistItemId: bidProd.wishlistitemId,
        price: newPrice,

      };
      console.log(data);

      axios
        .post("/make_bidLot", data)
        .then((response) => {
          if (response.status === 202) {
            console.log("HELLO");
            toast.error("Deadline for bidding has passed");
          }
          else if (response.status === 203) {
            console.log("HELLO");
            toast.error("Bidding Limit Reached, Upgrade your subscription");
          }
          else {
            toast.success("Bid submitted for review");
            setShowBidModal(false);
          }

          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });


    }

  };
  // const handleChange = (event, bidProd) => {
  //     tempPr.newPrice = event.target.value;

  // };

  const handleShare = async () =>
  {
    await navigator.clipboard.writeText(`https://invx.infoarc.ai/product-detail/${type}/${id}`);
    setIsShareLinkCopied(true);
  }

  const handleCloseShareModal = () =>
  {
    setShowShareModal(false);
    setIsShareLinkCopied(false); 
  }

  return (
    <>
      <SearchHeader
      wishListCount={wishListCount}
      wishListTotal = {wishListTotal}
      cartCount={cartItemCount}
      cartTotal={cartPriceTotal}
      />
      <Header logOut={props.logOut} />

      {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      /> */}

      <ToastContainerBootstrap className="showToast">
        <Toast
          autohide={true}
          show={showAddedToCartToast}
          onClose={() => setShowAddedToCartToast(false)}
        >
          <Toast.Header>
            <strong className="me-auto">Cart</strong>
            <small>1 second ago</small>
          </Toast.Header>
          <Toast.Body>Product added to cart.</Toast.Body>
        </Toast>

        <Toast
          autohide={true}
          show={showAddedToWishListToast}
          onClose={() => setShowAddedToWishListToast(false)}
        >
          <Toast.Header>
            <strong className="me-auto">WishList</strong>
            <small>1 second ago</small>
          </Toast.Header>
          <Toast.Body onClick={() => navigate("/negotiate-cart")}>
            Product added to wishlist.
          </Toast.Body>
        </Toast>
      </ToastContainerBootstrap>

      <div className="container app_container product_detail">
        <div className="d_sm_center">
          <h1
            className="text-gray-600 font-bold text-[25px] inline-block float-left"
            style={{ color: "#0d6efd" }}
          >
            {type === "product" ? "PRODUCT DETAIL" : "LOT DETAIL"}
          </h1>
        </div>

        <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
          <ul className="page-hierarchy">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/search-product/All%20Categories">Search Product</a>
            </li>
            <li>
              <a href={`/product-detail/${type}/${id}`}>{name}</a>
            </li>
          </ul>
        </div>
        {/* <br />
        <br />
        <hr style={{ width: "100%" }} /> */}
        <br />

        <div className="row w-100 justify-content-around">
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-12 position-relative d-flex">
                <img loading="lazy" 
                  src={`${isValidImage(image) ? image : require("../../images/no-image.jpg")}`}
                  className="product_main_img border shadow-xl border-slate-100"
                  style={{
                    width: "200px",  // Adjust width as needed
                    height: "200px"  // Adjust height as needed
                  }}
                  alt="product"
                />
                <div className="rating product_ratings">
                  <div className="product_share">
                    <input
                      type="radio"
                      name="rating-3"
                      className="h-[70px] whishlist_icon bg-slate-200 hover:bg-red-600"
                      style={{
                        fontWeight: `${favourites.indexOf(name) > -1 ? "900" : ""
                          }`,
                      }}
                      onClick={handlePicWishlist}
                    />
                  </div>
                  <div className="product_share cursor-pointer p-2 rounded-md hover:text-red-600 bg-slate-200 hover:bg-blue-500" onClick = {() => setShowShareModal(true)}>
                    <svg
                      style={{ color: 'white' }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="16"
                      fill="currentColor"
                      className="bi bi-share"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
                        fill="white"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row align-items-right justify-content-between mt-5">
              {images.map((value, i) => {
                return (
                  <div className="col-4 col-md">
                    <div key={value} className="imageListContainer">
                      <img loading="lazy" 
                        onClick={() => {
                          setImage(images[i]);
                        }}
                        alt="product-images"
                        src={`${process.env.REACT_APP_SERVER_URL}/static/images/${value}`}
                        className={`opacity-[0.6] float-left inline-block ${value ===
                          images[
                          images.findIndex((value) => {
                            return image === value;
                          })
                          ]
                          ? "opacity-[1]"
                          : ""
                          }`}
                      />
                    </div>
                  </div>
                );
              })}

              <div className="col-4 col-md">
                <div
                  className="bg-slate-200 hover:bg-slate-500 w-[30px] h-[30px] p-1"
                  style={{
                    margin: "2em auto",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    const imageIndex = images.findIndex(
                      (value) => value === image
                    );

                    if (imageIndex < images.length - 1) {
                      setImage(images[imageIndex + 1]);
                    } else {
                      setImage(images[0]);
                    }
                  }}
                >
                  <svg
                    style={{ color: "white", cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
              </div>
            </div> */}
            <div className="row align-items-right justify-content-between mt-5">
              {images.map((value, i) => {
                return (
                  <div key={value} className="col-4 col-md">
                    <div className="imageListContainer">
                      <span
                        onClick={() => {
                          setImage(images[i]);
                        }}
                        className={`bullet ${value === image ? "active" : ""}`}
                      >•</span>
                    </div>
                  </div>
                );
              })}
            </div>

          </div>
          <div className="product_detail_container col-12 col-md-8">
            <h3
              className="d_sm_center text-[24px] font-bold"
              style={{ fontWeight: "600", textTransform: "uppercase" }}
            >
              {name}
            </h3>
            <div
              className="flex flex-row rating rating-sm space-x-1"
              style={{ marginTop: "1.2em", marginBottom: "1.2em" }}
              onClick={() => openRatingModal(state)}
            >
              <div className="star-rating">
                {
                  [...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;
                    const displayRating = rating > 0 ? rating : state?.rating;
                    return (
                      <label key={i}>
                        <input
                          type="radio"
                          name="rating"
                          value={displayRating}
                          hidden
                          disabled
                        />
                        <span className={displayRating >= ratingValue ? "star filled" : "star"}>&#9733;</span>
                      </label>
                    );
                  })
                }
              </div>

              {/* <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400"
              />
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400"
              />
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400"
              />
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400"
              />
              <input
                type="radio"
                name="rating-6"
                className="mask mask-star-2 bg-orange-400"
              /> */}
            </div>
            <div className='description_row'>
              <div className={`description_column ${type !== 'lot' ? 'w-100' : ''}`}>
                <h3
                  className="text-[17px] text-slate-400"
                  style={{ fontWeight: "600" }}
                >
                  OVERVIEW
                </h3>

                {Object.entries(details).length > 0 ? (
                  <div
                    className="row"
                    style={{ marginBottom: "1em", fontSize: "15px" }}
                  >
                    {Object.entries(details).map(([key, value]) => (
                      <div key={key} className="d-flex">
                        <div
                          className="col-6 text-slate-400"
                          style={{ display: "inline-block" }}
                        >
                          {key}
                        </div>
                        <div
                          className="col-6 product_value"
                          style={{ display: "inline-block" }}
                        >
                          {value}
                        </div>
                      </div>
                    ))}
                    {/* {itemsInside.map((item, index) => (
                      <div key={index}>
                        {Object.keys(item).map(key => (
                          <div key={key} className="d-flex">
                            <div className="col-6 text-slate-400" style={{ display: "inline-block" }}>
                              {key}
                            </div>
                            <div className="col-6 product_value" style={{ display: "inline-block" }}>
                              {item[key]}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))} */}


                  </div>
                ) : (
                  <h6
                    className="text-[15px]"
                    style={{ marginBottom: "1em", fontWeight: "400" }}
                  >
                    No details found.
                  </h6>
                )}
              </div>
              {type === 'lot' &&
                <div className="description_column">
                  <>
                    <div className="d-flex" style={{ fontSize: "15px" }}>
                      <h3 className="col-6 text-slate-400" style={{ fontSize: "17px", fontWeight: "600", display: "inline-block" }}>
                        Items
                      </h3>
                      <h3 className="col-6 text-slate-400" style={{ fontSize: "17px", fontWeight: "600", display: "inline-block" }}>
                        Quantity
                      </h3>
                    </div>

                    {/* <div className="d-flex">
                      <div className="col-6 text-slate-400" style={{ display: "inline-block" }}>
                        Item 1
                      </div>
                      <div  className="col-6 product_value"
                          style={{ display: "inline-block" }}
                        >
                        12
                      </div>
                    </div> */}
                    <div>
                      {itemsInside.map((item, index) => (
                        <div key={index} className="d-flex">
                          {Object.entries(item).map(([key, value]) => (
                            <React.Fragment key={key}>
                              <div className="col-6 text-slate-400" style={{ display: "inline-block" }}>
                                {key}
                              </div>
                              <div className="col-6 product_value" style={{ display: "inline-block" }}>
                                {value}
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>

                  </>
                </div>
              }
            </div>

            {/* <p className="purchase_in text-[17px] text-slate-400" style={{ fontWeight: '600' }}>PURCHASE IN</p>
            <div className="row product_type_row">
              <div className='col-8 col-md-6 col-lg-4'>
                <button className="btn border-none btn-active hover:bg-blue-400" style={{ width: '100%', backgroundColor: `${purchaseMethod === 'box' ? '#74A8F0' : '#E0E0E0'}`, color: `${purchaseMethod === 'box' ? 'white' : 'black'}` }} onClick={() => { setPurchaseMethod('box') }}>BOX</button>
              </div>
              <div className='col-8 col-md-6 col-lg-4'>
                <button className="btn border-none btn-active hover:bg-blue-400" style={{ width: '100%', backgroundColor: `${purchaseMethod === 'palette' ? '#74A8F0' : '#E0E0E0'}`, color: `${purchaseMethod === 'palette' ? 'white' : 'black'}` }} onClick={() => { setPurchaseMethod('palette') }}>PALETTE</button>
              </div>
              <div className='col-8 col-md-6 col-lg-4'>
                <button className="btn border-none btn-active hover:bg-blue-400" style={{ width: '100%', backgroundColor: `${purchaseMethod === 'crate' ? '#74A8F0' : '#E0E0E0'}`, color: `${purchaseMethod === 'crate' ? 'white' : 'black'}` }} onClick={() => { setPurchaseMethod('crate') }}>CRATE</button>
              </div>
            </div> */}
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }} className="grid_small_screen">
              {type === 'product' ? (
                <>
                  {state?.itemExpiry && (
                    <div className="product_expiry">
                      <p
                        className="text-[17px] text-slate-400"
                        style={{ fontWeight: "600", marginBottom: "0.3em" }}
                      >
                        BATCH EXPIRY DATE
                      </p>
                      <span style={{ color: "black" }}>{state?.itemExpiry}</span>
                    </div>
                  )}

                  <div>
                    <p
                      className="text-[17px] text-slate-400"
                      style={{ fontWeight: "600", marginBottom: "0.3em" }}
                    >
                      MAXIMUM ORDER QUANTITY
                    </p>
                    <span style={{ color: "black" }}>
                      {state?.itemPublishedQuantity}
                    </span>
                  </div>
                  {/* <div className="product_expiry">
                    <p
                      className="text-[17px] text-slate-400"
                      style={{ fontWeight: "600", marginBottom: "0.3em" }}
                    >
                      BATCH EXPIRY DATE
                    </p>
                    <span style={{ color: "black" }}>{state?.itemExpiry}</span>
                  </div> */}
                </>
              ) : (
                type === 'lot' && (
                  <div>
                  </div>
                )
              )}
            </div>

            {/* <br /> */}
            {purchaseMethod && (
              <>
                {type === 'product' ? (
                  <div className="d-flex flex-column justify-content-center gap-2">
                    <div className="row">
                      <div className="col-12 d-flex flex-column align-items-center">
                        <hr className="d_sm_none" />
                        <div>
                          <p className="text-custom-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold">
                            {state?.itemPrice} {(currentUser && currentUser.currency) ? currentUser.currency : 'USD'}/
                            <span
                              className="text-[15px]"
                              style={{ textTransform: "uppercase" }}
                            >
                              {state?.itemUom}
                            </span>
                          </p>
                        </div>
                        <div>
                          <span
                            className="bg-slate-700 text-white"
                            style={{ padding: "3.5px 8px", cursor: "pointer" }}
                            onClick={decrementQuanity}
                          >
                            <span style={{ marginTop: "-3px" }}>-</span>
                          </span>
                          <input
                            type="number"
                            value={quantity}
                            className="w-[90px] pl-10 h-7 border border-slate-300"
                            style={{ backgroundColor: "white" }}
                            onChange={handleQuantityChangeItem}
                          />
                          <span
                            className="bg-slate-700 text-white"
                            style={{ padding: "3.5px 8px", cursor: "pointer" }}
                            onClick={incrementQuanity}
                          >
                            <span style={{ marginTop: "-3px" }}>+</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center gap-2 product_details_button_group">
                        {state?.itemBid === "Open Bidding" ? (
                          null
                        ) : state?.itemBid === "Close Bidding" ? (
                          null
                        ) : (
                          <button
                            style={{ width: "200px" }}
                            onClick={addToCart}
                            className="btn btn-outline-primary btn-block"
                            disabled={state?.itemPublishedQuantity < state?.itemMinQuantity}
                          >
                            Add to Cart
                          </button>
                        )}
                        <button
                          style={{ width: "200px" }}
                          onClick={chatWithSeller}
                          className="btn btn-outline-primary btn-block"
                        >
                          Chat with seller
                        </button>
                        <button
                          style={{ width: "200px" }}
                          onClick={addToWishList}
                          className="btn btn-outline-primary btn-block"
                          disabled={state?.itemPublishedQuantity < state?.itemMinQuantity}
                        >
                          Add to WishList
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  type === 'lot' && (
                    <div className="row">


                      <hr />
                      <div>
                        <p className="text-[30px] font-bold">
                          {/* <span
                  className="text-[15px]"
                  style={{ textTransform: "uppercase" }}
                >
                  {state?.itemUom}
                </span> */}
                        </p>
                      </div>
                      <div>
                        {/* <span
                className="bg-slate-700 text-white"
                style={{ padding: "3.5px 8px", cursor: "pointer" }}
                onClick={decrementQuanity}
              >
                <span style={{ marginTop: "-3px" }}>-</span>
              </span> */}

                        {/* <span
                className="bg-slate-700 text-white"
                style={{ padding: "3.5px 8px", cursor: "pointer" }}
                onClick={incrementQuanity}
              >
                <span style={{ marginTop: "-3px" }}>+</span>
              </span> */}
                      </div>
                      <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                        {state?.lotBid === "Open Bidding" ? (
                          <>
                            <button
                              style={{ width: "200px" }}
                              onClick={handleViewBids}
                              className="btn btn-outline-primary"
                            >
                              View Bids
                            </button>
                            <button
                              style={{ width: "200px" }}
                              onClick={handleBid}
                              className="btn btn-outline-primary"
                              disabled={state?.isSold === 1}
                            >
                              Submit Bid
                            </button>
                          </>
                        ) : state?.lotBid === "Close Bidding" ? (
                          <button
                            style={{ width: "200px" }}
                            onClick={handleBid}
                            className="btn btn-outline-primary"
                            disabled={state?.isSold === 1}
                          >
                            Submit Bid
                          </button>
                        ) : (
                          <button
                            style={{ width: "200px" }}
                            onClick={addToCart}
                            className="btn btn-outline-primary"
                            disabled={state?.isSold === 1}
                          >
                            Add to Cart
                          </button>
                        )}
                        <button
                          style={{ width: "200px" }}
                          onClick={chatWithSeller}
                          className="btn btn-outline-primary"
                        >
                          Chat with seller
                        </button>
                        <button
                          style={{ width: "200px" }}
                          onClick={addToWishList}
                          className="btn btn-outline-primary"
                          disabled={state?.isSold === 1}
                        >
                          Add to WishList
                        </button>
                      </div>

                    </div>
                  )
                )}
              </>

            )}


          </div>
        </div>
        <hr className="product_description_hr" />
        <div className="product_description">
          <h4 className="font-bold">DETAIL DESCRIPTION</h4>
          <br />
          {
            type === 'product' && (
              <span className="text-[15px]">{state?.itemDesc}</span>
            )
          }
          {
            type === 'lot' && (
              <span className="text-[15px]">{state?.lotDesc}</span>
            )
          }

        </div>
        <br />
        <div className="feedback_preview">
          <h4 className="font-bold d_sm_center">REVIEWS</h4>
          <Row className="mb-3">
            {feedbacks.length === 0 ? (
              <Col className="d_sm_center">
                <p>No reviews made yet.</p>
              </Col>
            ) : (
              feedbacks.slice(0, 2).map((feedback, index) => (
                <Col md={6} key={index}>
                  <Card className="rounded">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-2">
                        <img loading="lazy"  className='user_avatar' src={teamImage} alt="" style={{ marginRight: "10px" }} />
                        <div>
                          <strong>{feedback.username}</strong>
                          <div>{feedback.reviewCount} reviews</div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <Card.Text>
                          <div className="d-flex align-items-center">
                            <div className="star-rating">
                              {[...Array(5)].map((star, i) => {
                                const ratingValue = i + 1;
                                return (
                                  <span key={i} className={feedback.rating >= ratingValue ? "star filled" : "star"}>&#9733;</span>
                                );
                              })}
                            </div>
                            <span className="ml-2">{formatDate(feedback.createdOn)}</span>
                          </div>
                        </Card.Text>
                      </div>
                      <Card.Text>{feedback.feedback}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          {feedbacks.length > 2 && (
            <span onClick={() => setShowModalFeedback(true)} className="see-more-link">
              {'See More >>'}
            </span>
          )}


        </div>



        <br />
        <br className="d_sm_none" />
        <div className="product_documents">
          <h4 className="font-bold">PRODUCT DOCUMENTS</h4>
          {files && files.length > 0 ? (
            <div className="mt-[1em] product-document">
              {files.map((file, index) => (
                <figure key={index} style={{ display: "inline-block" }}>
                  <img loading="lazy" 
                    className="mt-[1em]"
                    alt="product-documents"
                    src={require("../../images/document.png")}
                    style={{ width: "150px", height: "150px", margin: "auto" }}
                    onClick={() =>
                      openProductDocument(
                        `${file}`
                      )
                    }
                  />
                  {/* {index === 0 && <figcaption style={{ textAlign: 'center', fontSize: '12px', marginTop: '2px' }}>
          {state?.itemFileName}
          </figcaption>} */}
                  {docName && docName.length > 0 && (
                    <figcaption
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        marginTop: "2px",
                      }}
                    >
                      {docName[index]}
                    </figcaption>
                  )}
                </figure>
              ))}
            </div>
          ) : (
            <>
              {/* <br /> */}
              <span className="text-[15px]">
                No documents found for this product.
              </span>
            </>
          )}
        </div>
        {/* <br />
        <hr /> */}


        {/* <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
          <div className="flex flex-row space-x-2">
            <div className="bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" /> </svg>
            </div>
            <div className="bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
              <svg style={{ color: 'rgb(78, 77, 70)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill="#4e4d46"></path> </svg>
            </div>
          </div>
        </div> */}
        <br />
        <br />
        <br />
        {/* <div className="row related_products">
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
            <div className="product_card product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
              <figure
                style={{ margin: "0", height: "200px", cursor: "pointer" }}
                onClick={() => handleClick("Raw Materials")}
              >
                <img loading="lazy" 
                  src={require("../../images/image002.jpg")}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </figure>
              <div className="card-body">
                <div className="rating rating-sm space-x-2">
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                </div>
                <div
                  className="flex flex-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <h2 className="text-[14px] mt-1">ISO Propyl Alcohol</h2>
                  <div className="rating w-30px" style={{ float: "right" }}>
                    <input
                      type="radio"
                      name="rating-3"
                      className="whishlist_icon bg-slate-200 hover:bg-red-600"
                      style={{
                        fontWeight: `${favourites.indexOf("ISO Propyl Alcohol") > -1
                            ? "900"
                            : ""
                          }`,
                      }}
                      onClick={() => addToWishList("ISO Propyl Alcohol")}
                    />
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
              </div>
              <div className="product_price" style={{ padding: "0 1em" }}>
                <p className="text-[14px]">
                  Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                  consectetur.
                </p>
                <br />
                <h1
                  className="text-[20px] font-bold mb-3"
                  style={{ color: "#0d6efd" }}
                >
                  $225.50
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
              <figure
                style={{ margin: "0", height: "200px", cursor: "pointer" }}
                onClick={() => handleClick("Packaging Materials")}
              >
                <img loading="lazy" 
                  src={require("../../images/image001.png")}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </figure>
              <div className="card-body">
                <div className="rating rating-sm space-x-2">
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                </div>
                <div
                  className="flex flex-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <h2 className="text-[14px] mt-1">Wood</h2>
                  <div className="rating w-30px" style={{ float: "right" }}>
                    <input
                      type="radio"
                      name="rating-3"
                      className="whishlist_icon bg-slate-200 hover:bg-red-600"
                      style={{
                        fontWeight: `${favourites.indexOf("Wood") > -1 ? "900" : ""
                          }`,
                      }}
                      onClick={() => addToWishList("Wood")}
                    />
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
              </div>
              <div className="product_price" style={{ padding: "0 1em" }}>
                <p className="text-[14px]">
                  Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                  consectetur.
                </p>
                <br />
                <h1
                  className="text-[20px] font-bold mb-3"
                  style={{ color: "#0d6efd" }}
                >
                  $225.50
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
              <figure
                style={{ margin: "0", height: "200px", cursor: "pointer" }}
                onClick={() => handleClick(null)}
              >
                <img loading="lazy" 
                  src="https://placeimg.com/400/225/arch"
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </figure>
              <div className="card-body">
                <div className="rating rating-sm space-x-2">
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                </div>
                <div
                  className="flex flex-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <h2 className="text-[14px] mt-1">Sample Product 1</h2>
                  <div className="rating w-30px" style={{ float: "right" }}>
                    <input
                      type="radio"
                      name="rating-3"
                      className="whishlist_icon bg-slate-200 hover:bg-red-600"
                      style={{
                        fontWeight: `${favourites.indexOf("Sample Product 1") > -1
                            ? "900"
                            : ""
                          }`,
                      }}
                      onClick={() => addToWishList("Sample Product 1")}
                    />
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
              </div>
              <div className="product_price" style={{ padding: "0 1em" }}>
                <p className="text-[14px]">
                  Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                  consectetur.
                </p>
                <br />
                <h1
                  className="text-[20px] font-bold mb-3"
                  style={{ color: "#0d6efd" }}
                >
                  $225.50
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
              <figure
                style={{ margin: "0", height: "200px", cursor: "pointer" }}
                onClick={() => handleClick(null)}
              >
                <img loading="lazy" 
                  src="https://placeimg.com/400/225/arch"
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </figure>
              <div className="card-body">
                <div className="rating rating-sm space-x-2">
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                </div>
                <div
                  className="flex flex-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <h2 className="text-[14px] mt-1">Sample Product 2</h2>
                  <div className="rating w-30px" style={{ float: "right" }}>
                    <input
                      type="radio"
                      name="rating-3"
                      className="whishlist_icon bg-slate-200 hover:bg-red-600"
                      style={{
                        fontWeight: `${favourites.indexOf("Sample Product 2") > -1
                            ? "900"
                            : ""
                          }`,
                      }}
                      onClick={() => addToWishList("Sample Product 2")}
                    />
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
              </div>
              <div className="product_price" style={{ padding: "0 1em" }}>
                <p className="text-[14px]">
                  Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                  consectetur.
                </p>
                <br />
                <h1
                  className="text-[20px] font-bold mb-3"
                  style={{ color: "#0d6efd" }}
                >
                  $225.50
                </h1>
              </div>
            </div>
          </div>
        </div> */}
        <div className="related_products_section">
          <h4 className="related_products_heading text-gray-600 font-bold text-[25px] inline-block float-left mt-[13px]">
            RELATED
          </h4>
          <ProductRow />
        </div>

        <Footer />

        {fileName && (
          <Modal
            centered
            show={showModal}
            onHide={handleCloseModal}
            className="product-document"
          >
            <Modal.Header closeButton={handleCloseModal}></Modal.Header>
            <Modal.Body>
              <object
                data={`${fileName}`}
                aria-labelledby="pdf-viewer"
                type="application/pdf"
                style={{ width: "100%", height: "580px" }}
              ></object>
            </Modal.Body>
          </Modal>
        )}
        {/* {fileName && (
    <Modal
        centered
        show={showModal}
        onHide={handleCloseModal}
        className="product-document"
    >
        <Modal.Header closeButton={handleCloseModal}></Modal.Header>
        <Modal.Body>
            <canvas id="pdf-canvas" style={{ width: "100%", height: "580px" }}></canvas>
        </Modal.Body>
    </Modal>
)} */}

        <TxModal
          title={txTitle}
          msg={txMsg}
          show={showTxModal}
          setShow={setShowTxModal}
          confirmTx={reveal}
          type={txModalType}
          login={true}
        />
        <div>

          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Bids for {state?.lotName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {bids.length > 0 ? (
                <Table className="bids-table" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Buyer</th>
                      <th>Bid</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bids.map((bid, index) => (
                      <tr key={index}>
                        <td className="buyer-name">*****</td>
                        <td>{bid.newBid} {(currentUser && currentUser.currency) ? currentUser.currency : 'USD'}</td>
                        <td>{bid.Status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p className="no-bids-text">No bids have been made for this item yet.</p>
              )}
            </Modal.Body>
          </Modal>


        </div>

        {product && (
          <BidModal showModal={showBidModal} handleClose={() => setShowBidModal(false)} product={product} handleEnter={handleEnter} />

        )}
        <Modal show={showModalRating} onHide={() => setShowModalRating(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Rate {currentProduct?.type === 'product' ? currentProduct?.itemName : currentProduct?.lotName}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* Star Rating */}
            <div className="star-rating">
              {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;
                return (
                  <label key={i}>
                    <input
                      type="radio"
                      name="rating"
                      value={ratingValue}
                      hidden
                      onClick={() => setRating(ratingValue)}
                    />
                    <span className={rating >= ratingValue ? "star filled" : "star"}>&#9733;</span>
                  </label>
                );
              })}
            </div>
            <textarea placeholder="Enter Feedback" value={feedback} onChange={e => setFeedback(e.target.value)} className="form-control mt-2" />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModalRating(false)}>Close</Button>
            <Button variant="primary" onClick={handleRatingSubmit}>Submit</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalFeedback} onHide={() => setShowModalFeedback(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>All Feedbacks</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {feedbacks.slice(2).map((feedback, index) => (
              <div key={index} className="mb-3">
                <div className="d-flex align-items-center mb-2">
                  <img loading="lazy"  className='user_avatar' src={teamImage} alt="" style={{ marginRight: "10px" }} />
                  <div>
                    <strong>{feedback.username}</strong>
                    <div>{feedback.reviewCount} reviews</div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="star-rating">
                    {[...Array(5)].map((star, i) => {
                      const ratingValue = i + 1;
                      return (
                        <span key={i} className={feedback.rating >= ratingValue ? "star filled" : "star"}>&#9733;</span>
                      );
                    })}
                  </div>
                  <span className="ml-2">{formatDate(feedback.createdOn)}</span>
                </div>
                <div>
                  <p>{feedback.feedback}</p>
                </div>
                {index !== feedbacks.slice(2).length - 1 && <hr />} {/* Separate feedbacks by a line but not the last one */}
              </div>
            ))}
          </Modal.Body>
        </Modal>




        {showAddToWishlist && (
          <AddToWishlistPic
            showPrompt={showPrompt}
            showQuantityInput={showQuantityInput}
            handlePromptResponse={handlePromptResponse}
            handleQuantityChange={handleQuantityChange}
            handleSaveQuantity={handleSaveQuantity}
            quantityPic={quantityPic}
            minQuantity={state?.itemMinQuantity}
            maxQuantity={state?.itemPublishedQuantity}
          />
        )}
      </div>

      <Modal show = {showShareModal} centered onHide = {handleCloseShareModal}>
        <Modal.Header closeButton>
          <Modal.Title>Share {state?.itemName}</Modal.Title>
        </Modal.Header>

        <Modal.Body className = "share-link-container">
          <div className = "share-link">
            <input 
              type = "text" 
              defaultValue = {`https://invx.infoarc.ai/product-detail/${type}/${id}`} 
              disabled 
            />

            <button type = "button" className = "px-2 self-stretch text-[20px] cursor-pointer border-1 rounded-2 hover:bg-slate-100" onClick = {handleShare}>
              <FaRegCopy />
            </button>
          </div>

          {isShareLinkCopied && (
            <Alert variant = "success" className = "mt-2 text-[12px] p-1 border-1">
              <span>
                <FaCircleCheck />
                Link copied. Anyone with the link can view.
              </span>
            </Alert>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductDetail;