import React, { useState, useEffect } from 'react';
import { ProSidebar, SidebarHeader, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { AiFillHome, AiOutlineHeart } from 'react-icons/ai';
import { BsFillChatFill, BsListUl, BsCart3, BsBuildingsFill, BsReverseListColumnsReverse, BsCardChecklist, BsCardList } from 'react-icons/bs';
import { RiAdminFill, RiGitPullRequestFill, RiListCheck, RiLockPasswordFill, RiMoneyDollarBoxFill, RiPlayListAddLine } from 'react-icons/ri';
import { IoIosCreate, IoIosListBox } from 'react-icons/io';
import { PiListChecksBold } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from "axios";
import Logo from '../../images/Logo.png';
import 'react-pro-sidebar/dist/css/styles.css';
import '../../css/sidebar.css';
import { FaFileInvoiceDollar, FaListAlt, FaListOl, FaListUl, FaThList, FaUser, FaUserEdit, FaUsers, FaFileUpload } from 'react-icons/fa';
import { IoExtensionPuzzle } from 'react-icons/io5';
import { BiSolidPurchaseTagAlt, BiSolidReport } from 'react-icons/bi';

const Sidebar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isItemListingOpen, setItemListingOpen] = useState(false);
    const [isAdminOpen, setAdminOpen] = useState(false);
    const [isDashboardAllowed, setIsDashboardAllowed] = useState(false);
    const [isDashboardOpen, setDashboardOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));

    useEffect(() => {
        const fetchLogoUrl = async () => {
            try {
                const response = await axios.get(`/get_company_logo`, { params: { userId: currentUser.id } });
                if (response.data && response.data.logoURL) {
                    localStorage.setItem('logoUrl', response.data.logoURL);
                } else {
                    console.error('Logo URL not found in response');
                }
            } catch (error) {
                console.error('Error fetching logo URL:', error);
            }
        };
    
        fetchLogoUrl();
    }, []); 

    useEffect(() => {
        axios.get('/dashboard-allowed', { params: { user_id: currentUser.id } })
            .then(response => {
                if (response.data.dashboard === 'enable') {
                    setIsDashboardAllowed(true);
                } else {
                    setIsDashboardAllowed(false);
                }
            })
            .catch(error => {
                console.error('Error checking dashboard access:', error);
                // Optionally set the state to false or handle the error as needed
            });
        window.matchMedia("(max-width: 1024px)").addEventListener('change', e => setMatches(e.matches));
    }, []);

    const handleSubMenuState = (state) => {
        setIsOpen(state);
    }

    return (
        <ProSidebar collapsed={matches ? false : props.collapsed} className={`${matches && !props.collapsedDropdown && 'dashboard_menu_collapsed'}`}>
            {!matches && (
                <SidebarHeader>
                    <a href="/">
                        <img loading="lazy"  src={Logo} alt="Inv-X" className="border-logo" style={{ margin: '0.87em auto' }} />
                    </a>
                </SidebarHeader>
            )}
            <SidebarContent>
                <Menu iconShape="square">
                    {props?.userRole?.access === "admin" && (
                        <MenuItem icon={<AiFillHome />} title="Dashboard" active={window.location.pathname === "/admin/dashboard"}>
                            Dashboard
                            <Link to="/admin/dashboard" />
                        </MenuItem>
                    )}
                    {[2, 4].includes(currentUser.role) && (
                        <SubMenu
                            title="Item Listing"
                            icon={<IoIosListBox />}
                            open={window.location.pathname.includes("item-listing") ? true : isItemListingOpen}
                            onOpenChange={(state) => setItemListingOpen(state)}
                        >
                            <MenuItem icon={<IoIosCreate />} title="Create Item" active={window.location.pathname === "/admin/item-listing/create-item"} style={{ fontSize: '14px' }}>
                                Create Item
                                <Link to="/admin/item-listing/create-item" />
                            </MenuItem>
                            <MenuItem icon={<RiPlayListAddLine />} title="Create Lot" active={window.location.pathname === "/admin/item-listing/create-lot"} style={{ fontSize: '14px' }}>
                                Create Lot
                                <Link to="/admin/item-listing/create-lot" />
                            </MenuItem>

                            <MenuItem icon={<PiListChecksBold />} title="Inventory List" active={window.location.pathname === "/admin/item-listing/inventory-list"} style={{ fontSize: '14px' }}>
                                Inventory List
                                <Link to="/admin/item-listing/inventory-list" />
                            </MenuItem>
                            <MenuItem icon={<FaListUl />} title="Lot List" active={window.location.pathname === "/admin/item-listing/lot-list"} style={{ fontSize: '14px' }}>
                                Lot List
                                <Link to="/admin/item-listing/lot-list" />
                            </MenuItem>
                            <MenuItem icon={<FaListOl />} title="Published List" active={window.location.pathname === "/admin/item-listing/published-products"} style={{ fontSize: '14px' }}>
                                Published List
                                <Link to="/admin/item-listing/published-products" />
                            </MenuItem>
                        </SubMenu>
                    )}

                    {[1,2, 4].includes(currentUser.role) && (
                        <MenuItem icon={<BsFillChatFill />} title="Chat Support" active={window.location.pathname === "/admin/chat-support"}>
                            Chat Support
                            <Link to="/admin/chat-support" />
                        </MenuItem>
                    )}

                    {[1, 4].includes(currentUser.role) && (
                        <MenuItem icon={<FaFileInvoiceDollar />} title="Invoices" active={window.location.pathname === "/admin/invoices"}>
                            Invoices
                            <Link to="/admin/invoices" />
                        </MenuItem>
                    )}
                    {[2, 4].includes(currentUser.role) && (
                        <MenuItem icon={<BiSolidPurchaseTagAlt />} title="Purchase Orders" active={window.location.pathname === "/admin/purchase-orders"}>
                            Purchase Orders
                            <Link to="/admin/purchase-orders" />
                        </MenuItem>
                    )}

                    {[1, 4].includes(currentUser.role) && (
                        <MenuItem
                            icon={<FaThList />}
                            title="Sales Requests"
                            active={window.location.pathname === "/admin/sales-requests"}
                        >
                            My Buying List
                            <Link to="/admin/sales-requests" />
                        </MenuItem>
                    )}
                    {[2, 4].includes(currentUser.role) && (
                        <MenuItem
                            icon={<FaListAlt />}
                            title="Purchase Requests"
                            active={window.location.pathname === "/admin/purchase-requests"}
                        >
                            My Selling List
                            <Link to="/admin/purchase-requests" />
                        </MenuItem>
                    )}
                    {currentUser.role === 3 && (
                        <SubMenu
                            title="Admin"
                            icon={<RiAdminFill />}
                            open={window.location.pathname.includes("admin-section") ? true : isAdminOpen}
                            onOpenChange={(state) => setAdminOpen(state)}
                        >
                            <MenuItem icon={<FaUsers />} title="Users" active={window.location.pathname === "/admin/admin-section/users"} style={{ fontSize: '14px' }}>
                                Users
                                <Link to="/admin/admin-section/users" />
                            </MenuItem>
                            <MenuItem icon={<IoExtensionPuzzle />} title="Extensions" active={window.location.pathname === "/admin/admin-section/extension-requests"} style={{ fontSize: '14px' }}>
                                Extension Requests
                                <Link to="/admin/admin-section/extension-requests" />
                            </MenuItem>
                            <MenuItem icon={<RiGitPullRequestFill />} title="RFQ Requests" active={window.location.pathname === "/admin/admin-section/rfq-requests"} style={{ fontSize: '14px' }}>
                                e-RFQ Requests
                                <Link to="/admin/admin-section/rfq-requests" />
                            </MenuItem>
                            {/* <MenuItem icon={<FaUsers />} title="Lot Update Requests" active={window.location.pathname === "/admin/admin-section/update-requests"} style={{ fontSize: '14px' }}>
                            Lot Update Requests
                            <Link to="/admin/admin-section/update-requests" />
                        </MenuItem> */}
                            <MenuItem icon={<BsBuildingsFill />} title="Company Details" active={window.location.pathname === "/admin/admin-section/company-details"} style={{ fontSize: '14px' }}>
                                Company Details
                                <Link to="/admin/admin-section/company-details" />
                            </MenuItem>
                            <MenuItem icon={<FaUsers />} title="Demo Requests" active={window.location.pathname === "/admin/admin-section/demo-requests"} style={{ fontSize: '14px' }}>
                                Demo Requests
                                <Link to="/admin/admin-section/demo-requests" />
                            </MenuItem>

                            <MenuItem icon = {<FaFileUpload />} title = "Bulk Upload" active = {window.location.pathname === "/admin/admin-section/import-bulk-list"} style = {{ fontSize: '14px' }}>
                                Import Bulk List
                                <Link to = "/admin/admin-section/import-bulk-list" />
                            </MenuItem>
                        </SubMenu>
                    )}
                    {(currentUser.role === 3 && isDashboardAllowed) && (
                        <SubMenu
                            title="Dashboard"
                            icon={<RiAdminFill />}
                            open={window.location.pathname.includes("dashboard") ? true : isDashboardOpen}
                            onOpenChange={(state) => setDashboardOpen(state)}
                        >


                            <MenuItem icon={<BiSolidReport />} title="Items Report" active={window.location.pathname === "/dashboard/all-items"} style={{ fontSize: '14px' }}>
                                Items Report
                                <Link to="/dashboard/all-items" />
                            </MenuItem>
                            <MenuItem icon={<BiSolidReport />} title="Item Sales Report" active={window.location.pathname === "/dashboard/sales-report"} style={{ fontSize: '14px' }}>
                                Item Sales Report
                                <Link to="/dashboard/sales-report" />
                            </MenuItem>
                            <MenuItem icon={<BiSolidReport />} title="Category Sales Report" active={window.location.pathname === "/dashboard/category-sales-report"} style={{ fontSize: '14px' }}>
                                Sales by Category Report
                                <Link to="/dashboard/category-sales-report" />
                            </MenuItem>
                            <MenuItem icon={<BiSolidReport />} title="Lots Report" active={window.location.pathname === "/dashboard/all-lots"} style={{ fontSize: '14px' }}>
                                Lots Report
                                <Link to="/dashboard/all-lots" />
                            </MenuItem>
                            <MenuItem icon={<BiSolidReport />} title="Lot Sales Report" active={window.location.pathname === "/dashboard/lot-sales-report"} style={{ fontSize: '14px' }}>
                                Lot Sales Report
                                <Link to="/dashboard/lot-sales-report" />
                            </MenuItem>
                            <MenuItem icon={<BiSolidReport />} title="Users Report" active={window.location.pathname === "/dashboard/user-reports"} style={{ fontSize: '14px' }}>
                                Users Report
                                <Link to="/dashboard/user-reports" />
                            </MenuItem>



                            <MenuItem icon={<BiSolidReport />} title="company Report" active={window.location.pathname === "/dashboard/company-reports"} style={{ fontSize: '14px' }}>
                                Company Report
                                <Link to="/dashboard/company-reports" />
                            </MenuItem>
                            <MenuItem icon={<BiSolidReport />} title="Inactive Companies Report" active={window.location.pathname === "/dashboard/inactive-report"} style={{ fontSize: '14px' }}>
                                Inactive Companies
                                <Link to="/dashboard/inactive-report" />
                            </MenuItem>

                            <MenuItem icon={<BiSolidReport />} title="Invoice & PO Report" active={window.location.pathname === "/dashboard/article-reports"} style={{ fontSize: '14px' }}>
                                Invoice & PO Report
                                <Link to="/dashboard/article-reports" />
                            </MenuItem>
                        </SubMenu>
                    )}
                    {/* <MenuItem icon = {<GiCoins />} title = "My Sales List" active = {window.location.pathname === "/admin/sales-list"}>
                        My Sales List
                        <Link to = "/admin/sales-list" />
                    </MenuItem> */}
                    <hr />
                    <SubMenu
                        title="Profile"
                        icon={<FaUser />}
                        open={window.location.pathname.includes("profile") ? true : isProfileOpen}
                        onOpenChange={() => setIsProfileOpen(!isProfileOpen)}>
                        <MenuItem icon={<FaUserEdit />} title="Edit Profile" active={window.location.pathname === "/admin/profile/edit-profile"}>
                            Edit Profile
                            <Link to="/admin/profile/edit-profile" />
                        </MenuItem>
                        <MenuItem icon={<RiLockPasswordFill />} title="Change Password" active={window.location.pathname === "/admin/profile/change-password"}>
                            Change Password
                            <Link to="/admin/profile/change-password" />
                        </MenuItem>
                        <MenuItem icon={<RiLockPasswordFill />} title="Subscription Profile" active={window.location.pathname === "/subscription-profile"}>
                            Subscription Profile
                            <Link to="/subscription-profile" />
                        </MenuItem>
                    </SubMenu>
                    {[1, 4].includes(currentUser.role) && (
    <>
        <MenuItem 
            icon={<AiOutlineHeart />} 
            title="My Wish List" 
            active={window.location.pathname === "/negotiate-cart"}
        >
            My Wish List
            <Link to="/negotiate-cart"/>
        </MenuItem>
        <MenuItem 
            icon={<BsCart3 />} 
            title="My Cart" 
            active={window.location.pathname === "/cart"}
        >
            My Cart
            <Link to="/cart"/>
        </MenuItem>
    </>
)}

                    {/* {[1, 4].includes(currentUser.role) && (
    <>
        <MenuItem 
            icon={<AiOutlineHeart />} 
            title="My Wish List" 
            active={window.location.pathname === "/negotiate-cart"}
        >
            My Wishlist
            <Link to="/negotiate-cart"/>
        </MenuItem>
        <MenuItem 
            icon={<BsCart3 />} 
            title="My Cart" 
            active={window.location.pathname === "/cart"}
        >
            My Cart
            <Link to="/cart"/>
        </MenuItem>
    </>
)} */}

                    
                </Menu>
            </SidebarContent>
        </ProSidebar>
    );
}

const mapStateToProps = state => {
    return {
        userRole: state.userRole
    };
};

export default connect(mapStateToProps)(Sidebar);