import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../css/Popup.css';

const PopUp = ({ showPopUp = false, isSubmittingRequest = false, handleSubmitRequest = () => {}, handleClosePopUp = () => {} }) => 
{
  const submitButtonRef = useRef(null);

  return (
    <Modal
      show = {showPopUp}
      onHide = {handleClosePopUp}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Request Inventory
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit = {handleSubmitRequest}>
          <div className = "input-container">
            <label htmlFor = "itemName" className = "input-label">Item Name:</label>
            <input type = "text" id = "itemName" className = "input-field" required />
          </div>

          <div className = "input-container">
            <label htmlFor = "itemQuantity" className = "input-label">Quantity:</label>
            <input type = "number" id = "itemQuantity" className = "input-field" required />
          </div>

          <div className = "input-container">
            <label htmlFor = "itemUoM" className = "input-label">Unit of Measurement:</label>
            <input type = "text" id = "itemUoM" className = "input-field" required />
          </div>

          <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant = "secondary" onClick = {handleClosePopUp}>
          Close
        </Button>
        <Button variant = "primary" onClick = {() => submitButtonRef?.current?.click()}>
          <div className = "d-flex align-items-center gap-2">
            Submit

            {isSubmittingRequest && (
              <div className = "spinner-border text-info" role = "status"></div>
            )}
          </div>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PopUp;
