import React, { useState, useEffect } from 'react';
import Header from '../header/Header';
import { makeStyles } from '@mui/styles';
import { Card, Row, Col } from 'react-bootstrap';
import PopUp from './PopUp';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import bg_2_1 from '../../images/9.png';
import bg_2_2 from '../../images/3.png';
import bg_2_3 from '../../images/8.png';
import bg_5 from '../../images/10.png';
import chat from '../../images/chat.png'
import '../../css/style.css';
import '../../css/search.css';
import 'react-multi-carousel/lib/styles.css';
import { Plugins } from '@capacitor/core';
import LiveItems from '../product/LiveItems';
const useStyles = makeStyles(() => ({

  textFieldContainer: {
    border: "none !important"
  },
  textFieldInput: {
    [`& input`]: {
      border: "none !important"
    }
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none"
    }
  }
}));

const HomePage = (props) => {
  const { Storage } = Plugins;
  const [selected, setSelected] = useState("All Categories");
  const classes = useStyles();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const navigate = useNavigate();
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  )
  const [values, setValues] = useState([]);
  const [items, setItems] = useState("");

  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);
  

  function handleChange(event) {
    setSelected(event.target.value);
  }

  function handleSearch(event) {
    setItems(event.target.value);
    console.log(items);
  }
  function handleClick() {
    console.log(selected);
    navigate(`/search-product/${selected}`, { state: items });
  }
  const openPopup = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const submitRequest = async (e) => {
    e.preventDefault();
    closePopup();

    const itemName = e.target.itemName.value;
    const itemQuantity = e.target.itemQuantity.value;
    const itemUom = e.target.itemUoM.value;
    const userId = currentUser.id;
    try {
      const response = await axios.post('/erfq_request', {
        userId,
        itemName,
        itemQuantity,
        itemUom
      });

      console.log(response.data);

    } catch (error) {
      console.error('Error sending request:', error);
    }

  };
  useEffect(() => {
    axios.get('/get_categories')
      .then(response => {
        const categories = response.data.map(category => category.categoryName);
        console.log(categories);
        setValues(categories);
      })
      .catch(error => {
        console.log(error);
      });
  }, [])

  return (
    <>
      <Header logOut={props.logOut} />
      <div className='container-fluid homepage p-0'>
        <div className='homepage_hero'>
          <Card className="bg-1 category_filter_bar app_hero">
            {!matches && (
              <h3 className='hero_headline' style={{ marginBottom: '1em', textAlign: 'left', width: '100%' }}>Welcome to Inv-X.</h3>
            )}
            <Row className='w-100'>
              <Col xs={12} className="bg-1-sub-1">
                <button className="bg-1-sub-button" type="button">Fast moving items</button>
                <button className="bg-1-sub-button" type="button">Inventory near you</button>

              </Col>
            </Row>
            <Row className='search_field_row mb-[0.5em]'>
              <Col xs={12} className="bg-1-sub-2 p-0 search-bar" style={{ padding: '0%' }}>
                <div className="form-group" style={{ width: '100%' }}>
                  {/* <input type="text" className="form-control homePageInput" placeholder="Search" onChange={handleSearch} /> */}
                  <input
                    type="text"
                    className="form-control homePageInput"
                    placeholder="Search"
                    onChange={handleSearch}
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        handleClick();
                      }
                    }}
                  />

                </div>

                <select className="form-control homePageDropdown text-center sm:text-left mx-auto" onChange={handleChange} value={selected}>
                  {values.map((value, index) => {
                    return <option value={value} key={index}>{value}</option>
                  })}
                </select>
                <button className="bg-1-sub-2-button home_page_search" type="button" onClick={handleClick} style={{ height: '30px' }}>Search</button>

              </Col>

              <Col xs={12} md={8} className="bg-1-sub-2 request_inventory justify-content-sm-center request_inventory" style={{ padding: '0' }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    openPopup();
                  }}
                  style={{ marginLeft: '10px', display: 'block', marginTop: '10px', color: 'white' }}
                >
                  Request Inventory
                </a>
              </Col>
            </Row>
            {matches && <h3 className='hero_headline'>
              Welcome to Inv-X.
            </h3>}
          </Card>
        </div>
        <h2 className="grey_heading">
          Get access to thousands of inventory<br></br>items ready to dispatch.
        </h2>
        <LiveItems />

        <div className='container app_container'>


          {
            currentUser ?
              (currentUser.role === 2 || currentUser.role === 4) ?
                <Row className='text-center mt-3'>
                  <h2 className="grey_heading">
                    Looking to sell inventory?
                  </h2>
                  <br></br>
                  <h2 className="grey_heading">
                    <a className="visit" href="/admin/item-listing/inventory-list">VISIT</a> your inventory management portal.
                  </h2>
                </Row>
                :
                null
              :
              <Row className='text-center mt-3'>
                <h2 className="grey_heading">
                  Looking to sell inventory?
                </h2>
                <br></br>
                <h2 className="grey_heading">
                  <a className="visit" href="/admin/login">VISIT</a> your inventory management portal.
                </h2>
              </Row>
          }


          <section className='subscription_sec d-lg-flex'>
            <Card className="subscription_banner">
              <Row className='subscription_card_row'>
                <Col className="bg-4-1 subscription_card_col" md={8}>
                  <h3 className='white_heading'> Subscribe to Inv-X Premium<br></br>and Save More</h3>
                </Col>
                <Col className="bg-4-2" md={3}>
                  <button className="bg-4-2-button" type="button" onClick={() => { navigate("/pricing") }}>Read More<span className="bg-4-2-span"></span></button>
                </Col>
              </Row>
            </Card>
            <div className='chat_section'>
              <p>
                Chat directly with suppliers & gain confidence on your purchase.
              </p>
              <img loading="lazy" className="chat" src={chat} alt="chat" />
            </div>
          </section>
        </div>
        <img loading="lazy" className="bg-5" src={bg_5} alt="bg_5" />
        <div className='app_container'>

          <Row className="bg-2 services_card">
            <Col xs={12} sm={4} className="bg-2-sub-1">
              <img loading="lazy" className="bg-2-1" src={bg_2_1} alt="bg_2_1" />
              <span className="bg-2-text-1">PROTECTIVE</span>
              <br></br>
              <span className="bg-2-text-2">GEAR</span>
            </Col>
            <Col xs={12} sm={4} className="bg-2-sub-2">
              <img loading="lazy" className="bg-2-2" src={bg_2_2} alt="bg_2_2" />
            </Col>
            <Col xs={12} sm={4} className="bg-2-sub-3">
              <img loading="lazy" className="bg-2-3" src={bg_2_3} alt="bg_2_3" />
            </Col>
          </Row>
        </div>


      </div>
      <PopUp
        showPopUp = {isPopupVisible}
        handleSubmitRequest = {submitRequest}
        handleClosePopUp = {closePopup}
      />
    </>
  );
}

export default HomePage;