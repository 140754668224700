import { useEffect, useState } from 'react';
import { DataGrid, GridContextProvider } from '@mui/x-data-grid';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosSearch } from 'react-icons/io';
import Stack from '@mui/material/Stack';
import Header from './Header';
import Sidebar from './Sidebar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ToastContainer from 'react-bootstrap/ToastContainer';
import CustomDownloader from './CustomDownloader';
import axios from 'axios';
import TxModal from './TxModal';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/style.css';
import '../../css/inventory.css';

const styles = {
  container: {
    height: '500px'
  }
};

const LotList = (props) => 
{
  const navigate = useNavigate();

  /* AUTHENTICATION VARIABLES */
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));

  /* HEADER VARIABLES */
  const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
  const [collapseDropdown, setCollapseDropdown] = useState(false);

  /* RESPONSIVENESS VARIABLES */
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);

  /* API VARIABLES */
  const [isLoading, setIsLoading] = useState(false);

  /* DATATABLE VARIABLES */
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);

  /* PAGINATION VARIABLES */
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 20;

  /* SEARCH VARIABLES */
  const [searchInput, setSearchInput] = useState(null);
  const [toggleSearch, setToggleSearch] = useState(false);

  /* MODAL VARIABLES */
  const [showModal, setShowModal] = useState(false);

  /* CUSTOM MODAL VARIABLES */
  const [showTxModal, setShowTxModal] = useState(false);
  const [txTitle, setTxTitle] = useState("");
  const [txModalType, setTxModalType] = useState();
  const [txMsg, setTxMsg] = useState("");

  useEffect(() =>
  {
    window
    .matchMedia("(max-width: 1024px)")
    .addEventListener("change", (event) => setMatches(event.matches));
  }, []);

  useEffect(() => 
  {
    const fetchData = async () =>
    {
      setIsLoading(true);

      const offset = page * pageSize;

      await axios
      .post(`/lots/${currentUser?.id}`, {
        limit: pageSize,
        offset: offset,
        searchInput: searchInput
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200)
        {
          const rowData = data?.lots || [];
          const expectedOrder = ['LotId', 'Name', 'Description', 'BidChoice', 'Deadline', 'Price', 'Commission', 'Published', 'TotalPrice'];
    
          // Create dynamic columns based on the expected order
          const tempColumns = expectedOrder.map((columnName) => 
          {
            let column = {
              field: columnName,
              headerName: addSpacesToColumnName(columnName),
              flex: 1
            };
    
            if (typeof rowData[0][columnName] === "number") 
            {
              column.type = "number";
              column.align = "right";
            } 
            else if (typeof rowData[0][columnName] === "string") 
            {
              column.align = "left";
            } 
            else 
            {
              column.align = "center";
            }
    
            if (columnName === "Price" || columnName === "TotalPrice") 
            {
              column.renderCell = (params) => `${params.value} ${currentUser.currency}`;
            }
    
            return column;
          });
    
          tempColumns.push({
            field: 'Actions',
            headerName: 'Actions',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            minWidth:200,
            flex: 1,
            align: 'center',
            renderCell: (params) => (
              <div>
                <FontAwesomeIcon
                  icon = {faTrash}
                  className = "mr-5"
                  onClick = {() => handleDeleteSingleLotWrapper(params.row.LotId, params.row.Name)}
                />

                <FontAwesomeIcon
                  icon = {faEdit}
                  className = "mr-5"
                  onClick = {() => 
                  {
                    navigate('/admin/item-listing/update-lot', {
                      state: params.row
                    });
                  }}
                />
                 
                <FontAwesomeIcon
                  icon = {faEye}
                  onClick = {() => 
                  {
                    navigate('/admin/item-listing/view-lot', {
                      state: params.row
                    });
                  }}
                />
              </div>
            )
          });
    
          setColumns(tempColumns);
          setRows(rowData);
          setFilteredRows(rowData);
          setTotalPages(data?.lot_count || 0);
        }
        else
        {
          toast.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => 
      {
        console.log("Get Lot List Api: ", error);
        setIsLoading(false);
        toast.error("Something went wrong. Please try again later.");
      });  
    }

    fetchData();
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [page, toggleSearch]);

  const addSpacesToColumnName = (str) => str.replace(/([A-Z])/g, ' $1').trim();

  const getCollapseSidebar = (value) => 
  {
    setCollapseSidebar(value);
  }

  const getCollapseDropdown = (value) => 
  {
    setCollapseDropdown(value);
  }
  
  const handleRowSelection = (rows) => 
  {
    setSelectedItems(rows);
  }

  const handleInputChange = (event) => 
  {
    let userInput = event.target.value;
    userInput = userInput.trim();

    if (!userInput)
    {
      setPage(0);
      setToggleSearch(!toggleSearch);
    }

    setSearchInput(userInput);
  }

  const handleSearch = () =>
  {
    setPage(0);
    setToggleSearch(!toggleSearch);
  }

  const handleCreateLot = () => 
  {
    navigate("/admin/item-listing/create-lot");
  }

  const handlePublishLot = async () => 
  {
    if (selectedItems.length === 0) 
    {
      setShowModal(true);
    } 
    else 
    {
      await axios
      .post("/publish_lot", {
        lotIds: selectedItems,
        sellerId: currentUser?.id
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if(status === 200)
        {
          navigate("/admin/item-listing/published-products");
        }
        else
        {
          toast.error(data?.message || "Something went wrong. Please try again later.");
        }
      })
      .catch((error) => 
      {
        console.error("Publish Lot Api: ", error);
        setIsLoading(false);
        toast.error("Something went wrong. Please try again later.");
      });
    }
  }

  const handleDeleteSingleLotWrapper = (id, name) => 
  {
    setItemToDeleteId(id);
    setTxModalType(1);
    setTxTitle(`Deletion Confirmation`);
    setTxMsg(`Are you sure you want to delete ${name}?`);
    setShowTxModal(true);
  }
  
  const handleDeleteMultipleLotsWrapper = () => 
  {
    setItemToDeleteId(null);
    setTxModalType(1);
    setTxTitle(`Deletion Confirmation`);
    setTxMsg(`Are you sure you want to delete selected lots?`);
    setShowTxModal(true);
  }
  
  const handleDeleteSingleLot = async (LotId) => 
  {
    setIsLoading(true);
    await axios.delete(`/delete_lot/${LotId}`);
    setIsLoading(false);
  }

  const handleDeleteMultipleLots = async () => 
  {
    setIsLoading(true);
    setShowTxModal(false);

    // Map over selected lot ids and create an array of delete promises
    const deletePromises = selectedItems.map((LotId) => handleDeleteSingleLot(LotId));

    try 
    {
      // Attempt to delete all selected lots concurrently
      await Promise.all(deletePromises);

      // Update rows to only include lots that were NOT selected for deletion
      const updatedRows = rows.filter((row) => !selectedItems.includes(row.LotId));

      setRows(updatedRows);
      setFilteredRows(updatedRows);
      setSelectedItems([]); 
    } 
    catch (error) 
    {
      console.log("Delete Lot Api: ", error);
    }

    setIsLoading(false); 
    setShowTxModal(false);
  }

  const handleDeleteSingleItem = async () => 
  {
    setIsLoading(true);

    await axios
    .delete(`/delete_lot/${itemToDeleteId}`)
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        const updatedRows = rows.filter((row) => row.LotId !== itemToDeleteId);

        setRows(updatedRows);
        setFilteredRows(updatedRows);
        setSelectedItems([]);
        setShowTxModal(false);
      }
      else
      {
        toast.error("Something went wrong. Please try again later.");
      }
    })
    .catch((error) => 
    {
      console.log("Delete Lot Api: ", error);
      setIsLoading(false);
      toast.error("Something went wrong. Please try again later.");
    });
  }

  const handleCloseModal = () => 
  {
    setShowModal(false);
  }

  return (
    <div className = "inventory_list_page">
      {!currentUser ? (
        <Navigate to = "/admin/login" replace = {true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar = {getCollapseSidebar}
              getCollapseDropdown = {getCollapseDropdown}
              logOut = {props.logOut}
            />
          )}

          <div className = "admin_panel">
            <div className = "d-lg-flex">
              <div className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"}`}>
                <Sidebar
                  collapsed = {collapseSidebar}
                  collapsedDropdown = {collapseDropdown}
                />
              </div>

              <div className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"}`}>
                {!matches && (
                  <Header
                    getCollapseSidebar = {getCollapseSidebar}
                    getCollapseDropdown = {getCollapseDropdown}
                    logOut = {props.logOut}
                  />
                )}

                {props.message && (
                  <ToastContainer className = "messageToast">
                    <Toast
                      delay = {3000}
                      autohide
                      onClose = {() => props.setMessage(null)}
                    >
                      <Toast.Header>
                        <strong className = "me-auto">Message</strong>
                        <small>2 secs ago</small>
                      </Toast.Header>
                      <Toast.Body>{props.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                )}

                <div className = {`w-100 content_panel ${collapseSidebar ? "slide-in" : "slide-out"}`}>
                  <div style = {{ height: "100vh" }}>
                    <h3 className = "pageName_heading">
                      {localStorage.getItem('logoUrl') && (
                        <img
                          src = {localStorage.getItem('logoUrl')}
                          alt = "Company Logo"
                          style = {{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }}
                        />
                      )}

                      Lot List
                    </h3>

                    <div className = "listing row">
                      <div className = "col-12 col-md-6 h-[45px] position-relative">
                        <div className = "admin_panel_searchbar">
                          <input 
                            type = "text"
                            placeholder = "Search..."
                            className = "input py-[1.5em] px-[3em]"
                            onChange = {(event) => handleInputChange(event)}
                          />

                          <button
                            className = "search"
                            type = "button"
                            onClick = {handleSearch}
                          >
                            <IoIosSearch
                              style = {{ color: "black", fontSize: "18px" }}
                            />
                          </button>
                        </div>
                      </div>

                      <div className = "col-12 col-md-6 ">
                        <div className = "admin_couple_buttons">
                          <div className = "text-center">
                            <button
                              type = "button"
                              className = "createItemButton"
                              onClick = {handleCreateLot}
                            >
                              Create Lot
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style = {styles.container} className = "customCard">
                      <GridContextProvider>
                        <DataGrid
                          loading = {isLoading}
                          columns = {columns}
                          rows = {filteredRows}
                          getRowId = {(row) => row.LotId}
                          rowCount = {totalPages}
                          rowHeight = {40}
                          pageSize = {pageSize}
                          rowsPerPageOptions = {[]}
                          pagination
                          paginationMode = "server"
                          density = "compact"
                          checkboxSelection = {!isLoading}
                          disableExtendRowFullWidth = {true}
                          disableSelectionOnClick = {false}
                          selectionModel = {selectedItems}
                          onSelectionModelChange = {handleRowSelection}
                          onPageChange = {(newPage) => setPage(newPage)}
                          components = {{
                            NoRowsOverlay: () => (
                              <Stack height = "100%" alignItems = "center" justifyContent = "center">
                                There are no records to display
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack height = "100%" alignItems = "center" justifyContent = "center">
                                There are no records to display
                              </Stack>
                            )
                          }}
                          getRowClassName = {(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 
                              ? "dataTable_even_row" 
                              : "dataTable_odd_row"
                          }
                        />
                      </GridContextProvider>
                    </div>

                    {filteredRows && filteredRows.length > 0 && (
                      <>
                        <CustomDownloader
                          title = "Lot List"
                          rows = {filteredRows}
                        />
                        
                        <button
                          type = "button"
                          className = "publish ml-5"
                          onClick = {handlePublishLot}
                        >
                          Publish Lot(s)
                        </button>

                        <button
                          type = "button"
                          className = "publish"
                          onClick = {handleDeleteMultipleLotsWrapper}
                        >
                          Delete Lot(s)
                        </button>
                      </>
                    )}

                    <br />

                    <Modal centered show = {showModal} onHide = {handleCloseModal}>
                      <Modal.Body>
                        <h1 className = "text-slate-600 text-[18px] text-center p-[1em]">
                          Please select one or more items from the table before
                          publishing.
                        </h1>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant = "warning"
                          onClick = {handleCloseModal}
                          style = {{
                            margin: 'auto',
                            width: '100px',
                            color: 'white'
                          }}
                        >
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <TxModal
        show = {showTxModal}
        title = {txTitle}
        type = {txModalType}
        msg = {txMsg}
        login = {false}
        confirmTx = {itemToDeleteId ? handleDeleteSingleItem : handleDeleteMultipleLots}
        setShow = {setShowTxModal}
      />
    </div>
  );
}

export default LotList;