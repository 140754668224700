import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { BsUpload } from 'react-icons/bs';
import { AiFillFastForward, AiOutlineCheckCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { FaPencilAlt } from 'react-icons/fa';
import { IoIosUndo, IoIosArrowBack } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import InfiniteScroll from 'react-infinite-scroller';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Plus from '../../images/plus.svg';
import Tippy from '@tippyjs/react';
import Tooltip from './Tooltip';
import DataTable from 'react-data-table-component';
import Header from './Header';
import Sidebar from './Sidebar';
import axios from 'axios';
import 'tippy.js/dist/tippy.css'; 
import '../../css/style.css';
import '../../css/inventory.css';

const ImportBulkList = (props) =>  
{
  const navigate = useNavigate();

  /* AUTHENTICATION VARIABLES */
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));

  /* RESPONSIVENESS VARIABLES */
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);
  const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
  const [collapseDropdown, setCollapseDropdown] = useState(false);

  /* API VARIABLES */
  const [isLoading, setIsLoading] = useState(false);

  /* TAB VARIABLES */
  const [importStep, setImportStep] = useState(1);

  /* MAPPING VARIABLES */
  const [mappingColumns, setMappingColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [duplicateColumnIds, setDuplicateColumnIds] = useState(new Set());

  /* TABLE VARIABLES */
  const [tableColumns, setTableColumns] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  /* FORM VARIABLES */
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  /* FILE VARIABLES */
  const [fileData, setFileData] = useState(null);
  const [fileColumns, setFileColumns] = useState([]);
  const [filteredFileColumns, setFilteredFileColumns] = useState([]);
  const fileUpload = useRef(null);
  const dropdownRef = useRef(null);

  /* FILE UPLOAD ERROR VARIABLES */
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [visibleErrors, setVisibleErrors] = useState(100);
  const [hasMoreErrors, setHasMoreErrors] = useState(true);

  /* MODAL VARIABLES */
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showUploadErrorsModal, setShowUploadErrorsModal] = useState(false);

  /* LIST VARIABLES */
  const industries = useMemo(() => [
    "Aerospace",
    "Agriculture",
    "Computer",
    "Construction",
    "Education",
    "Electronics",
    "Energy",
    "Entertainment",
    "Food",
    "Health Care",
    "Hospitality",
    "Manufacturing",
    "Mining",
    "Music",
    "News Media",
    "Pharmaceutical",
    "Plastics",
    "Telecommunication",
    "Textile",
    "Transport",
    "World Wide Web"
  ], []);
  const [categories, setCategories] = useState([]);

  // Get category list from database table
  useEffect(() => 
  {
    axios
    .get("/get_categories")
    .then((response) => 
    {
      const responseData = response?.data || null;

      if (response.status === 200)
      {
        const categories = responseData?.map((category) => category.categoryName);
        setCategories(categories.slice(1));
      }
    })
    .catch((error) => 
    {
      console.log("Get Categories Api Error: ", error);
    });

    axios
    .get("/get_companies")
    .then((response) => 
    {
      const responseData = response?.data || null;

      if (response.status === 200)
      {
        const companies = responseData || [];

        if (companies.length === 0)
        {
          toast.info("No companies found in the system.");
        }

        setCompanies(companies);
      }
      else
      {
        toast.error("There was an error while retrieving the company accounts. Please try again later or contact the support team for further assistance.");
      }
    })
    .catch((error) => 
    {
      console.log("Get Companies Api Error: ", error);
      toast.error("There was an error while retrieving the company accounts. Please try again later or contact the support team for further assistance.");
    });
  }, []);

  // Initialize columns to map
  useEffect(() => 
  {
    const tempMappingColumns = [
      { 
        id: "Column 1", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "ItemName",
        isExtra: false,
        isRequired: true
      },
      { 
        id: "Column 2", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Industries",
        isExtra: false,
        isRequired: true
      },
      { 
        id: "Column 3", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "ItemCategory",
        isExtra: false,
        isRequired: true
      },
      { 
        id: "Column 4", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Packaging",
        isExtra: false,
        isRequired: true
      },
      { 
        id: "Column 5", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "UoM",
        isExtra: false,
        isRequired: true
      },
      { 
        id: "Column 6", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Price(US$)",
        isExtra: false,
        isRequired: true
      },
      { 
        id: "Column 7", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Location",
        isExtra: false,
        isRequired: true 
      },
      { 
        id: "Column 8", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Longitude",
        isExtra: false,
        isRequired: true
      },
      { 
        id: "Column 9", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Latitude",
        isExtra: false,
        isRequired: true 
      },
      { 
        id: "Column 10", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "ItemDescription",
        isExtra: false,
        isRequired: true 
      },
      { 
        id: "Column 11", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Size",
        isExtra: false,
        isRequired: false 
      },
      { 
        id: "Column 12", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "PhysicalState",
        isExtra: false,
        isRequired: false 
      },
      { 
        id: "Column 13", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Grade",
        isExtra: false,
        isRequired: false 
      },
      { 
        id: "Column 14", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "MaterialNumber",
        isExtra: false,
        isRequired: false 
      },
      { 
        id: "Column 15", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "ExpiryDate",
        isExtra: false,
        isRequired: false 
      },
      { 
        id: "Column 16", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "Quantity",
        isExtra: false,
        isRequired: true 
      },
      { 
        id: "Column 17", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "MinimumQuantity",
        isExtra: false,
        isRequired: true
      },
      { 
        id: "Column 18", 
        state: "Inactive", 
        value: "SELECT COLUMN NAME", 
        columnName: "PublishQuantity",
        isExtra: false,
        isRequired: true
      }
    ];
    
    setMappingColumns(tempMappingColumns);

    window.matchMedia("(max-width: 1024px)").addEventListener('change', e => setMatches(e.matches));
  }, []);

  // Detect change in dropdown input and filter dropdown values accordingly
  useEffect(() =>
  {
    const tempFileColumns = [...fileColumns];
    const filteredFileColumns = tempFileColumns.filter((column) =>
      column.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredFileColumns(filteredFileColumns);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchTerm]);

  // Detect click outside dropdown and reset input value 
  useEffect(() => 
  {
    function handleClickOutsideDropdown(event) 
    {
      if (dropdownRef?.current && !dropdownRef.current?.contains(event.target)) 
      {
        setSearchTerm("");
      }
    }
    
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    
    return () => 
    {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [dropdownRef]);

  const getCollapseSidebar = (value) => 
  {
    setCollapseSidebar(value);
  }

  const getCollapseDropdown = (value) => 
  {
    setCollapseDropdown(value);
  }

  const getCurrentDate = () =>
  {
    // Get the current date
    const date = new Date();

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    // Format the date as DD/MM/YYYY
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  const handlePreviousImportStep = (importStep) =>
  {
    if (importStep === 1)
    {
      const tempMappingColumns = [
        { 
          id: "Column 1", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "ItemName",
          isExtra: false,
          isRequired: true
        },
        { 
          id: "Column 2", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Industries",
          isExtra: false,
          isRequired: true
        },
        { 
          id: "Column 3", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "ItemCategory",
          isExtra: false,
          isRequired: true
        },
        { 
          id: "Column 4", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Packaging",
          isExtra: false,
          isRequired: true
        },
        { 
          id: "Column 5", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "UoM",
          isExtra: false,
          isRequired: true
        },
        { 
          id: "Column 6", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Price(US$)",
          isExtra: false,
          isRequired: true
        },
        { 
          id: "Column 7", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Location",
          isExtra: false,
          isRequired: true 
        },
        { 
          id: "Column 8", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Longitude",
          isExtra: false,
          isRequired: true
        },
        { 
          id: "Column 9", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Latitude",
          isExtra: false,
          isRequired: true 
        },
        { 
          id: "Column 10", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "ItemDescription",
          isExtra: false,
          isRequired: true 
        },
        { 
          id: "Column 11", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Size",
          isExtra: false,
          isRequired: false 
        },
        { 
          id: "Column 12", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "PhysicalState",
          isExtra: false,
          isRequired: false 
        },
        { 
          id: "Column 13", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Grade",
          isExtra: false,
          isRequired: false 
        },
        { 
          id: "Column 14", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "MaterialNumber",
          isExtra: false,
          isRequired: false 
        },
        { 
          id: "Column 15", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "ExpiryDate",
          isExtra: false,
          isRequired: false 
        },
        { 
          id: "Column 16", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "Quantity",
          isExtra: false,
          isRequired: true 
        },
        { 
          id: "Column 17", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "MinimumQuantity",
          isExtra: false,
          isRequired: true
        },
        { 
          id: "Column 18", 
          state: "Inactive", 
          value: "SELECT COLUMN NAME", 
          columnName: "PublishQuantity",
          isExtra: false,
          isRequired: true
        }
      ];

      setMappingColumns(tempMappingColumns);
      setSearchTerm("");
      setDuplicateColumnIds(new Set());
      setTableColumns([]);
      setTableRows([]);
      setSelectedCompany(null);
      setSelectedSeller(null);
      setIsFormSubmitted(false);
      setFileData(null);
      setFileColumns([]);
      setFilteredFileColumns([]);
      setErrorMessage("");
      setImportStep(1);
    }
    else if (importStep === 2)
    {
      setSearchTerm("");
      setDuplicateColumnIds(new Set());
      setTableColumns([]);
      setTableRows([]);
      setErrorMessage("");
      setImportStep(2);
    }
  }

  const handleBackPress = () =>
  {
    setSelectedCompany(null);
    setSelectedSeller(null);
    setIsFormSubmitted(false);
    setFileData(null);
    setFileColumns([]);
  }

  const handleSelectChange = async (event) =>
  {
    const { name, value } = event.target;

    if (name === "company")
    {
      setSelectedCompany(value);
      setIsLoadingUsers(true);
      
      const data = { companyId: value };

      await axios
      .post("/get_company_users", data)
      .then((response) => 
      {
        setIsLoadingUsers(false);
        const responseData = response?.data || null;

        if (response.status === 200)
        {
          const sellers = responseData?.company_users || [];

          if (sellers.length === 0)
          {
            toast.info("No sellers found for selected company.");
          }

          setSellers(sellers);
        }
        else
        {
          toast.error("There was an error while retrieving seller accounts for selected company. Please try again later or contact the support team for further assistance.");
        }
      })
      .catch((error) => 
      {
        console.log("Get Company Sellers Api Error: ", error);
        setIsLoadingUsers(false);
        toast.error("There was an error while retrieving seller accounts for selected company. Please try again later or contact the support team for further assistance.");
      });
    }
    else if (name === "seller")
    {
      setSelectedSeller(value);
    }
  }

  const handleFormSubmit = (event) =>
  {
    event.preventDefault(); 
    setIsFormSubmitted(true);
  }

  const handleFileUpload = () => 
  {
    fileUpload?.current?.click();
  }
  
  const handleFileChange = async (event) => 
  {
    if (event.target.files.length > 0) 
    {
      const inputFile = event.target.files[0];  
      const fileExtension = inputFile?.type.split("/")[1];

      if (fileExtension !== "vnd.openxmlformats-officedocument.spreadsheetml.sheet") 
      {
        toast.error("Please input a .xlsx file.");
        return;
      }

      setIsLoading(true);
      setUploadErrorMessages([]);
      setUploadErrors([]);
      setVisibleErrors(100);
      setHasMoreErrors(true)

      let data = new FormData();

      data.append("file", inputFile);
      data.append("current_user", currentUser?.id);

      await axios({
        method: "post",
        url: "/upload_file",
        timeout: 240000,
        data: data
      })
      .then((response) => 
      {
        setIsLoading(false);
        const responseData = response?.data;

        if (response.status === 200) 
        {
          console.log("Upload File Api: ", responseData);
          const fileData = responseData?.data || [];
          const fileDataObj = fileData?.[0] || {};
          const fileColumns = Object.keys(fileDataObj) || [];
          const requiredFileColumns = fileColumns.slice(0, 18);
          const extraFileColumns = fileColumns.slice(18);
          const tempMappingColumns = [...mappingColumns];

          tempMappingColumns.forEach(mappingColumn => 
          {
            const matchedColumn = requiredFileColumns.find(fileColumn => fileColumn.toLowerCase().includes(mappingColumn.columnName?.toLowerCase()?.replace(/\s/g, '')));

            if (matchedColumn) 
            {
              mappingColumn.value = matchedColumn;
            }
          });

          extraFileColumns.forEach(fileColumn =>
          {
            tempMappingColumns.push(
            {
              id: `Column ${tempMappingColumns.length + 1}`, 
              state: "Inactive", 
              value: fileColumn, 
              columnName: fileColumn,
              isExtra: true,
              isRequired: false
            })
          });

          setFileData(fileData);
          setFileColumns(fileColumns);
          setFilteredFileColumns(fileColumns);
          setMappingColumns(tempMappingColumns);
          setImportStep(2);
        }
        else if (response.status === 202)
        {
          console.log("Upload File Api: ", responseData);
      
          setUploadErrorMessages(responseData?.messages);
          setUploadErrors(responseData?.errors);
          setVisibleErrors(100);
          setShowUploadErrorsModal(true);
        }
        else
        {
          console.log("Upload File Api: ", responseData?.data);
          toast.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => 
      {
        console.log("Upload File Api: ", error);
        setIsLoading(false);
        toast.error("Something went wrong. Please try again later.");
      });
    }

    fileUpload.current.value = null;
  }

  const handleShowUploadErrors = (uploadErrors) => 
  {
    let errorCount = visibleErrors;
    let tempErrors = [];

    if (errorCount > uploadErrors?.length)
    {
      errorCount = uploadErrors?.length;
      setVisibleErrors(uploadErrors?.length);
    }

    for (let i = 0; i < errorCount; i++) 
    {
      tempErrors.push(
        <div key = {`${uploadErrors[i].row} - ${i}`} style = {{ marginTop: i === 0 && '0.5em' }}>
          <div className = "grid-container">
            <span style = {{ color: '#C62828' }}>Row {uploadErrors[i].row}</span>
            <span>{uploadErrors[i].column}:</span>
            <span>{uploadErrors[i].value}</span>
          </div>
        </div>
      );
    }

    return tempErrors;
  }

  const handleLoadMoreUploadErrors = () => 
  {
    if (uploadErrors?.length === visibleErrors) 
    {
      setHasMoreErrors(false);
    } 
    else 
    {
      setTimeout(() => 
      {
        setVisibleErrors(visibleErrors + 100);
      }, 2000);
    }
  }
  
  const handleDropdownChange = (selectedColumn, index) => 
  {
    const tempMappingColumns = [...mappingColumns];   

    tempMappingColumns[index].value = selectedColumn;
    if (tempMappingColumns[index].isExtra)
    {
      tempMappingColumns[index].columnName = selectedColumn;
    }

    setMappingColumns(tempMappingColumns);
    setSearchTerm("");
  }

  const handleEdit = (index) => 
  {
    const tempMappingColumns = mappingColumns.map((column, columnIndex) => ({
      ...column,
      state: columnIndex === index ? "Active" : column.state === "Active" ? "Inactive" : column.state,
      value: columnIndex === index ? "SELECT COLUMN NAME" : column.value
    }));

    setMappingColumns(tempMappingColumns);
  }

  const handleSkip = (index) =>
  {
    const tempMappingColumns = [...mappingColumns];

    tempMappingColumns[index].state = "Skipped";
    setMappingColumns(tempMappingColumns);
  }

  const handleUndo = (index) =>
  {
    const tempMappingColumns = [...mappingColumns];

    tempMappingColumns[index].state = "Inactive";
    setMappingColumns(tempMappingColumns);
  }

  const handleDone = (index) => 
  {
    const tempMappingColumns = [...mappingColumns];
    const value = tempMappingColumns[index].value;

    if (value === "SELECT COLUMN NAME")
    {
      tempMappingColumns[index].state = "Inactive";
    }
    else
    {
      tempMappingColumns[index].state = "Done";
    }
    
    setMappingColumns(tempMappingColumns);
    
    const tempFilteredMappingColumns = tempMappingColumns.filter(column => column.state !== "Skipped" && column.state !== "Extra" && column.value !== "SELECT COLUMN NAME");
    const seen = new Map();
    const duplicates = new Set();

    tempFilteredMappingColumns.forEach((column) => 
    {
      const id = column.id;
      const value = column.value;

      if (seen.has(value)) 
      {
        duplicates.add(seen.get(value));
        duplicates.add(id);
      } 
      else 
      {
        seen.set(value, id);
      }
    });

    if (duplicates?.size > 0)
    {
      setDuplicateColumnIds(duplicates);
      setErrorMessage("You are attempting to map the same file column multiple times.");
      setShowErrorModal(true);

      return; 
    }
    else
    {
      duplicates.clear();
      setDuplicateColumnIds(duplicates);
    }
  }

  const handleAdd = (index) =>
  {
    let tempMappingColumns = [...mappingColumns];
    const tempFilteredMappingColumns = tempMappingColumns.filter(column => column.state !== "Skipped" && column.state !== "Extra");
    const notAllColumnsMapped = tempFilteredMappingColumns.some(column => column.value === "SELECT COLUMN NAME");

    if (notAllColumnsMapped)
    {
      setErrorMessage("Please ensure all existing columns are mapped before adding new ones.");
      setShowErrorModal(true);

      return; 
    }
  
    tempMappingColumns = tempMappingColumns.map((column, columnIndex) => ({
      ...column,
      state: columnIndex === index ? "Active" : column.state === "Active" ? "Inactive" : column.state
    }));

    tempMappingColumns.push(
    { 
      id: `Column ${tempMappingColumns.length + 1}`, 
      state: "Extra", 
      value: "SELECT COLUMN NAME", 
      columnName: null,
      isExtra: true 
    });

    setMappingColumns(tempMappingColumns);
  }

  const handleProceed = () => 
  {
    const tempMappingColumns = mappingColumns.filter(column => column.state !== "Skipped" && column.state !== "Extra");
    const notAllColumnsMapped = tempMappingColumns.some(column => column.value === "SELECT COLUMN NAME");
    const seen = new Map();
    const duplicates = new Set();

    if (notAllColumnsMapped)
    {
      setErrorMessage("Please map all the columns.");
      setShowErrorModal(true);

      return; 
    }

    tempMappingColumns.forEach((column) => 
    {
      const id = column.id;
      const value = column.value;

      if (seen.has(value)) 
      {
        duplicates.add(seen.get(value));
        duplicates.add(id);
      } 
      else 
      {
        seen.set(value, id);
      }
    });

    if (duplicates?.size > 0)
    {
      setDuplicateColumnIds(duplicates);
      setErrorMessage("You are attempting to map the same file column multiple times.");
      setShowErrorModal(true);

      return; 
    }
    else
    {
      duplicates.clear();
      setDuplicateColumnIds(duplicates);
    }

    const tempTableColumns = [];
    const fileRow = fileData[0];

    for (let i = 0; i < tempMappingColumns.length; i++)
    {
      const field = tempMappingColumns[i].columnName;
      const header = tempMappingColumns[i].value;
      const isNumber = typeof(fileRow[header]) === "number" && !isNaN(fileRow[header]);

      tempTableColumns.push(
      {
        name: field,
        selector: (row) => row[header],
        'right': isNumber,
        cell: (row) => (
          <Tippy content = {row[header]}>
            <span className = "whitespace-nowrap overflow-hidden overflow-ellipsis">
              {row[header]}
            </span>
          </Tippy>
        ),
        style: { 
          justifyContent: isNumber ? 'right !important' : 'left'
        }
      });
    }

    setTableColumns(tempTableColumns);
    setTableRows(fileData);
    setImportStep(3);
  }

  const handleImport = async () => 
  {
    setIsLoading(true);

    await axios({
      method: "post",
      url: "/upload_bulk_inventory",
      timeout: 240000,
      data: {
        uploadedInventoryData: { 
          columns: tableColumns.map(column => column.name),
          rows: tableRows
        },
        userId: selectedSeller
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const responseData = response?.data;

      if (response.status === 200) 
      {
        console.log("Import Bulk Inventory Api: ", responseData);
  
        toast.success("Inventory bulk list imported successfully!");
        navigate("/search-product/All%20Categories");
      }
      else if (response.status === 202)
      {
        console.log("Import Bulk Inventory Api: ", responseData?.data);
        toast.error(responseData?.data);        
      }
      else
      {
        console.log("Import Bulk Inventory Api: ", responseData?.data);
        toast.error("Something went wrong. Please try again later.");
      }
    })
    .catch((error) => 
    {
      console.log("Import Bulk Inventory Api: ", error);
      setIsLoading(false);
      toast.error("Something went wrong. Please try again later.");
    });
  }

  const handleCloseModal = () => 
  {
    setErrorMessage("");
    setShowErrorModal(false);
    setShowUploadErrorsModal(false);
  }

  return (
    <>
      {!currentUser ? (
        <Navigate to = "/admin/login" replace = {true} />
      ) : (
        <>
          {matches && (
            <Header getCollapseSidebar = {getCollapseSidebar} getCollapseDropdown = {getCollapseDropdown} logOut = {props.logOut} />
          )}

          <div className = "admin_panel">
            <div className = "d-lg-flex">
              <div className = {`${collapseSidebar ? 'pinched_sidebar' : 'open_sidebar'}`}>
                <Sidebar collapsed = {collapseSidebar} collapsedDropdown = {collapseDropdown} />
              </div>

              <div className = {`inventory_list_content ${matches && 'open_content'}`} style = {{ width: `${collapseSidebar ? '95%' : '83%'}` }}>
                {!matches && (
                  <Header getCollapseSidebar = {getCollapseSidebar} getCollapseDropdown = {getCollapseDropdown} logOut={props.logOut} />
                )}

                <div className = {`content_panel w-100 ${collapseSidebar ? 'slide-in' : 'slide-out'}`}>
                  <div style = {{ height: '100vh' }}>
                    <div className = "d-lg-flex justify-content-between">
                      <div>
                        <h3 className = "pageName_heading">Import Inventory from File</h3>
                      </div>

                      <div className = "import-buttons">
                        <button className = {`import_progress ${importStep === 1 ? 'inactive' : ''}`} disabled = {isLoading} onClick = {() => handlePreviousImportStep(1)}>UPLOAD</button>
                        <hr className = "divider"></hr>
                        <button className = {`import_progress ${importStep === 2 ? 'inactive' : ''}`} disabled = {isLoading || importStep < 2} onClick = {() => handlePreviousImportStep(2)}>MAP COLUMNS</button>
                        <hr className = "divider"></hr>
                        <button className = {`import_progress ${importStep === 3 ? 'inactive' : ''}`} disabled = {isLoading || importStep < 3}>IMPORT</button>
                      </div>
                    </div>

                    {importStep === 1 ? (
                      <div className = "upload-container">
                        {isFormSubmitted ? (
                          <>
                            <input 
                              type = "file" 
                              ref = {fileUpload} 
                              className = "hidden"
                              accept = ".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              onChange = {handleFileChange} 
                            />
                            
                            <ButtonGroup>
                              <Button variant = "primary" onClick = {handleBackPress} disabled = {isLoading}><IoIosArrowBack fontSize = "20" /></Button>
                              <Button variant = "primary" className = "d-flex align-items-center gap-2" onClick = {handleFileUpload} disabled = {isLoading}>
                                {isLoading && (
                                  <div className = "spinner-border text-info" role = "status" style = {{ width: '20px', height: '20px' }}>
                                    <span className = "sr-only"></span>
                                  </div>
                                )}

                                Upload
                              </Button>
                            </ButtonGroup>
                            
                            <div className = "upload">
                              <BsUpload style = {{ fontSize: '80px', color: '#B9B9B9' }} />
                              <h6>No File Chosen</h6>
                              <span className = "w-fit m-auto mt-4">
                                <span className = "font-bold text-center !text-[15px] underline underline-offset-3">Instructions</span>
                                <ul className = "text-left mb-4 [&>li]:list-disc text-gray-500">
                                  <li>
                                    <b>Required Columns:</b> Values for column, ItemName, Industries, ItemCategory, Packaging, UoM, Price(US$), Location, Longitude, Latitude, Quantity, MinimumQuantity, and PublishQuantity, are mandatory.
                                  </li>
                                  <li>
                                    <b>Optional Columns:</b> Values for column, ItemDescription, Size, PhysicalState, Grade, MaterialNumber, and ExpiryDate, are optional.
                                  </li>
                                  <li>
                                    <b>Date Format:</b> Any date columns must adhere to the format DD/MM/YYYY, for example, {getCurrentDate()}.
                                  </li>
                                  <li>
                                    <b>PublishQuantity Validation:</b> The values in the PublishQuantity column must be greater than the MinimumQuantity and less than the Quantity.
                                  </li>
                                  <li>
                                    <b>Predefined Lists:</b> The values in the Industries and Category columns must match the predefined list of&nbsp;
                                    <p className = "m-0 bulk-import-tooltip inline-flex align-items-center gap-1">
                                      industries 
                                      <Tooltip content = {
                                        <List>
                                          {industries?.map((industry, index) => (
                                            <ListItem key = {index} disablePadding>
                                              <ListItemText primary = {industry} />
                                            </ListItem>
                                          ))}
                                        </List>
                                      }
                                      >
                                        <BsInfoCircle />
                                      </Tooltip>
                                    </p>
                                    &nbsp;and&nbsp;
                                    <p className = "m-0 bulk-import-tooltip inline-flex align-items-center gap-1">
                                      categories
                                      <Tooltip content = {
                                        <List>
                                          {categories?.map((category, index) => (
                                            <ListItem key = {index} disablePadding>
                                              <ListItemText primary = {category} />
                                            </ListItem>
                                          ))}
                                        </List>
                                      }
                                      >
                                        <BsInfoCircle />
                                      </Tooltip>
                                    </p>.
                                  </li>
                                  <li>
                                    <b>Dynamic Columns:</b> Any additional columns must begin with "EXTRA_LABEL" to differentiate them.
                                  </li>
                                  <li>
                                    <b>Dynamic Column Rules:</b> Corresponding "EXTRA_LABEL_NAME" and "EXTRA_LABEL_VALUE" columns must either both contain a value or both be empty.
                                  </li>
                                  <li>
                                    <b>Adding Dynamic Columns:</b> The first five dynamic columns are provided as EXTRA_LABEL_NAME_1, EXTRA_LABEL_VALUE_1, EXTRA_LABEL_NAME_2, EXTRA_LABEL_VALUE_2, etc. To add more, please use the same naming convention.
                                  </li>
                                </ul>
                                <a href = {require('../../documents/Item Bulk Upload Template.xlsx')} download = "Item Bulk Upload Template">Click here</a> to download a sample file.
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <form onSubmit = {handleFormSubmit}>
                              <h6>Fill in the details below before uploading items</h6>

                              <select className = "form-select" name = "company" defaultValue = "" onChange = {handleSelectChange} required>
                                <option value = "" hidden>Select Company</option>
                                {companies.map(company => (
                                  <option key = {company.id} value = {company.id}>{company.name}</option>
                                ))}
                              </select>

                              <div className = "user-list">
                                <select className = "form-select"  name = "seller" disabled = {!selectedCompany || isLoadingUsers} defaultValue = "" onChange = {handleSelectChange} required>
                                  <option value = "" hidden>Select Seller</option>
                                  {sellers.map(seller => (
                                    <option key = {seller.id} value = {seller.id}>{seller.name}</option>
                                  ))}
                                </select>

                                {isLoadingUsers && (
                                  <div className = "spinner-border text-info" role = "status" style = {{ width: '20px', height: '20px' }}>
                                    <span className = "sr-only"></span>
                                  </div>
                                )}
                              </div>

                              <button type = "submit">Submit</button>
                            </form>
                          </>
                        )}
                      </div>
                    ) : importStep === 2 ? (
                      <>
                        <div className = "mapColumns">
                          <h6 className = "text-[14px] font-light">Note: If no action is specified, the columns will be aligned automatically and added to the inventory.</h6>
                          <button className = "proceedButton" type = "button" onClick = {handleProceed}>PROCEED</button>
                        </div>
                        <br />

                        <div className = "mapColumnsContainer"> 
                          <table className = "mapColumnTable">
                            <colgroup>
                              {mappingColumns?.map((column) =>
                                <Fragment key = {column.id}>
                                  <col className = "outlined"></col>
                                  <col></col>
                                </Fragment>
                              )}
                            </colgroup>

                            <tbody>
                              <tr>
                                {mappingColumns?.map((column) =>
                                  <Fragment key = {column.id}>
                                    <td className = {`mapping-column-header ${duplicateColumnIds?.has(column.id) ? 'duplicate-column' : 'bg-white'} ${column.state === "Skipped" ? 'skipped-column' : ''}`}>
                                      {column.id.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })}
                                    </td>
                                    <td className = "space"></td>
                                  </Fragment>
                                )}
                              </tr>
                              <tr>
                                {mappingColumns?.map((column, index) =>
                                  <Fragment key = {column.id}>
                                    <td>
                                      {column.state === "Active" ? (
                                        <Fragment>
                                          <Dropdown ref = {dropdownRef} className = "mapColumnDropdownContainer" onSelect = {(newValue) => handleDropdownChange(newValue, index)}>
                                            <Dropdown.Toggle variant = "outline" className = "mapColumnDropdown" value = {column.value}>
                                              {column.value}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className = "map-column-dropdown-menu">
                                              <div className = "search-input-container">
                                                <input
                                                  type = "text"
                                                  placeholder = "Search..."
                                                  className = "search-input"
                                                  value = {searchTerm}
                                                  onChange = {(event) => setSearchTerm(event.target.value)}
                                                />
                                              </div>

                                              {filteredFileColumns?.map((column, index) => (
                                                <Dropdown.Item key = {index} eventKey = {column}>
                                                  {column}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        
                                          <div className = "btn-group">
                                            <div className = "mapColumnButtonContainer">
                                              <button className = "mapColumnButton skip" type = "button" onClick = {() => handleSkip(index)}>
                                                <AiFillFastForward className = "button-icon" style = {{ color: '#0d70a5' }} />
                                                SKIP
                                              </button>
                                              <button className = "mapColumnButton done" type = "button" onClick = {() => handleDone(index)}>
                                                <AiOutlineCheckCircle className = "button-icon" style = {{ color: 'white' }} />
                                                DONE
                                              </button>
                                            </div>
                                          </div>
                                        </Fragment>
                                      ) : column.state === "Extra" ? (
                                        <img src = {Plus} alt = "Add" className = "extra-column" onClick = {() => handleAdd(index)} />
                                      ) : (
                                        <Fragment>
                                          {(column.state === "Inactive" && column.value === "SELECT COLUMN NAME") ? (
                                            <span>UNMAPPED</span>
                                          ) : column.state === "Skipped" ? (
                                            <span className = "font-bold" style = {{ color: '#a3aeb3' }}>SKIPPED</span>
                                          ) : (
                                            <span className = "font-bold">{column.value}</span>
                                          )}
  
                                          <hr />
                                          
                                          <div className = "mapColumnButtonContainer">
                                            {!column.isRequired && (
                                              <Fragment>
                                                {column.isExtra && (
                                                  <button className = "mapColumnButton edit" type = "button" onClick = {() => handleEdit(index)}>
                                                    <FaPencilAlt className = "button-icon" style = {{ color: '#0d70a5', fontSize: '13px' }} />
                                                    EDIT
                                                  </button>
                                                )}

                                                {column.state !== "Skipped" ? (
                                                  <button className = "mapColumnButton edit" type = "button" onClick = {() => handleSkip(index)}>
                                                    <AiFillFastForward className = "button-icon" style = {{ color: '#0d70a5' }} />
                                                    SKIP
                                                  </button>
                                                ) : !column.isExtra && (
                                                  <button className = "mapColumnButton edit" type = "button" onClick = {() => handleUndo(index)}>
                                                    <IoIosUndo className = "button-icon" style = {{ color: '#0d70a5', fontSize: '13px' }} />
                                                    UNDO
                                                  </button>
                                                )}
                                              </Fragment>
                                            )}
                                          </div>    
                                        </Fragment>   
                                      )}
                                    </td>
                                    <td></td>
                                  </Fragment>
                                )}
                              </tr>
                              <tr>
                                {mappingColumns?.map((column) =>
                                  <Fragment key = {column.id}>
                                    <td className = "bg-white">{column.columnName ? `"${column.columnName}"` : null}</td>
                                    <td></td>
                                  </Fragment>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                      </>
                    ) : (
                      <>
                        <div className = "previewTable">
                          <h6 className = "text-[14px] font-light">Preview your spreadsheet before importing items.</h6>
                          <button className = "proceedButton d-flex align-items-center gap-2" type = "button" disabled = {isLoading} onClick = {handleImport}>
                            {isLoading && (
                              <div className = "spinner-border text-info" role = "status" style = {{ width: '20px', height: '20px' }}>
                                <span className = "sr-only"></span>
                              </div>
                            )}  

                            IMPORT
                          </button>
                        </div>
                        <br />

                        <div className = "table-overflow preview-item-listing">
                          <DataTable
                            columns = {tableColumns}
                            data = {tableRows}
                            pagination = {true}
                          />
                        </div>
                      </>
                    )}

                    <Modal centered show = {showErrorModal} onHide = {handleCloseModal}>
                      <Modal.Body>
                        <h1 className = "text-slate-600 text-[18px] text-center p-[1em]">{errorMessage}</h1>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant = "primary" size = "small" onClick = {handleCloseModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal centered className = "error-modal" show = {showUploadErrorsModal} onHide = {handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Error(s)</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <span className = "block mb-2">Please view the following error(s) found in the uploaded file.</span>
                          {uploadErrorMessages?.map((message, index) => (
                            <span key = {index} className = "upload-message block mb-1">{message}</span>
                          ))}

                          {(uploadErrors?.length > 0) && (
                            <InfiniteScroll
                              pageStart = {0}
                              loadMore = {handleLoadMoreUploadErrors}
                              hasMore = {hasMoreErrors}
                              loader = {
                                <div className = "bouncing-loader">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              }
                              useWindow = {false}
                            >
                              {handleShowUploadErrors(uploadErrors)}
                            </InfiniteScroll>
                          )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant = "danger" size = "small" color = "error" onClick = {handleCloseModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ImportBulkList;