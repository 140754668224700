import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridContextProvider } from '@mui/x-data-grid';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { IoIosSearch } from 'react-icons/io';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Stack from '@mui/material/Stack';
import TxModal from './TxModal';
import Header from './Header';
import Sidebar from './Sidebar';
import CustomDownloader from './CustomDownloader';
import moment from 'moment';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../../css/style.css';
import '../../css/inventory.css';

const styles = {
  container: {
    height: '500px'
  }
};
const buttonStyle = {
  backgroundColor: '#0d70a5',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '5px 10px',
  fontSize: '0.875rem',
  lineHeight: '1.5',
  marginLeft: '5px',
  marginRight: '5px'
};
const buttonStyle2 = {
  backgroundColor: '#4ca9d6',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '5px 10px',
  fontSize: '0.875rem',
  lineHeight: '1.5',
  marginLeft: '5px',
  marginRight: '5px'
};
const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
const quantityStyle = {
  width: '40px',
  textAlign: 'center',
  marginRight: '5px',
  marginLeft: '5px',
  backgroundColor: 'white'
};

const InventoryList = (props) => 
{
  const navigate = useNavigate();

  /* AUTHENTICATION VARIABLES */
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));

  /* HEADER VARIABLES */
  const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
  const [collapseDropdown, setCollapseDropdown] = useState(false);

  /* RESPONSIVENESS VARIABLES */
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);

  /* API VARIABLES */
  const [isLoading, setIsLoading] = useState(false);

  /* DATATABLE VARIABLES */
  const columns = useMemo(() => [
    {
      field: "InventoryNumber",
      headerName: "Inv. #",
      flex: 1,
      align: "right",
      width: 75,
      minWidth: 75
    },
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      width: 100,
      minWidth: 100
    },
    {
      field: "Description",
      headerName: "Description",
      flex: 1,
      valueGetter: (params) => params.row.Description || "",
      cellClassName: "wrap-text",
      width: 150,
      minWidth: 150
    },
    {
      field: "OnHandInventory",
      headerName: "Total Quantity",
      flex: 1,
      align: "right",
      width: 100,
      minWidth: 100
    },
    {
      field: "itemPrice",
      headerName: "Price Per Item",
      flex: 1,
      align: "right",
      valueGetter: (params) => params.row.itemPrice.toFixed(2) + " " + currentUser.currency,
      width: 100,
      minWidth: 100
    },
    {
      field: "TotalPrice",
      headerName: "Total Price Per Item",
      flex: 1,
      align: "right",
      valueGetter: (params) => params.row.TotalPrice.toFixed(2) + " " + currentUser.currency,
      width: 100,
      minWidth: 100
    },
    {
      field: "Quantity",
      headerName: "Min. Buying Quantity",
      flex: 1,
      align: "right",
      width: 150,
      minWidth: 150
    },
    {
      field: "alreadyPublished",
      headerName: "Published Quantity",
      flex: 1,
      align: "right",
      width: 150,
      minWidth: 150
    },
    {
      field: "lotQuantity",
      headerName: "Quantity in Lots",
      flex: 1,
      align: "right",
      width: 150,
      minWidth: 150
    },
    {
      field: "RemainingQuantity",
      headerName: "Available Quantity",
      flex: 1,
      align: "right",
      width: 150,
      minWidth: 150
    },
    {
      field: "publishedQuantity",
      headerName: "Quantity To Publish",
      flex: 2,
      editable: false,
      align: "center",
      width: 150,
      minWidth: 150,
      renderCell: (params) => (
        <div style = {containerStyle}>
          <button
            style = {buttonStyle2}
            onClick = {(event) => handleDecrementQuantity(params, event)}
          >
            {" "}
            -{" "}
          </button>
          
          <input
            type = "number"
            min = "0"
            value = {params.getValue(params.id, "publishedQuantity") || ""}
            placeholder = "Enter"
            onChange = {(event) => handleChangeQuantity(params, event)}
            style = {quantityStyle}
          />

          <button
            style = {buttonStyle}
            onClick = {(event) => handleIncrementQuantity(params, event)}
          >
            {" "}
            +{" "}
          </button>
        </div>
      )
    },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <div>
          <FontAwesomeIcon
            icon = {faTrash}
            className = "mr-5"
            onClick = {() => handleDeleteSingleItemWrapper(params.row.InventoryNumber, params.row.Name)}
          />

          <FontAwesomeIcon
            icon = {faEdit}
            className = "mr-5"
            onClick = {() => 
            {
              navigate("/admin/item-listing/update-item", {
                state: params.row
              });
            }}
          />

          <FontAwesomeIcon
            icon = {faEye}
            onClick = {() => 
            {
              navigate("/admin/item-listing/view-item", {
                state: params.row
              });
            }}
          />
        </div>
      )
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  ], []);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemDeletionType, setItemDeletionType] = useState(0);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);

  /* PAGINATION VARIABLES */
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 20;

  /* SEARCH VARIABLES */
  const [searchInput, setSearchInput] = useState(null);
  const [toggleSearch, setToggleSearch] = useState(false);

  /* MODAL VARIABLES */
  const [showModal, setShowModal] = useState(false);

  /* CUSTOM MODAL VARIABLES */
  const [showTxModal, setShowTxModal] = useState(false);
  const [txTitle, setTxTitle] = useState("");
  const [txModalType, setTxModalType] = useState();
  const [txMsg, setTxMsg] = useState("");

  useEffect(() =>
  {
    window
    .matchMedia("(max-width: 1024px)")
    .addEventListener("change", (event) => setMatches(event.matches));
  }, []);

  useEffect(() => 
  {
    const fetchData = async () =>
    {
      setIsLoading(true);

      const offset = page * pageSize;

      await axios
      .post(`/items/${currentUser?.id}`, {
        limit: pageSize,
        offset: offset,
        searchInput: searchInput
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;
  
        if (status === 200)
        {
          const rowData = data?.items || [];
  
          rowData.forEach(row => 
          {
            if (row.ExpiryDate) 
            {
              const formattedDate = moment(new Date(row.ExpiryDate)).format("DD MMM YYYY");
            
              row.ExpiryDate = formattedDate;
              row.Shipping = formattedDate;
            }
          });
          
          setRows(rowData);
          setFilteredRows(rowData);
          setTotalPages(data?.item_count || 0);
        }
        else
        {
          toast.error("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => 
      {
        console.log("Get Inventory List Api: ", error);
        setIsLoading(false);
        toast.error("Something went wrong. Please try again later.");
      });
    }

    fetchData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [page, toggleSearch]);

  const getCollapseSidebar = (value) => 
  {
    setCollapseSidebar(value);
  }

  const getCollapseDropdown = (value) => 
  {
    setCollapseDropdown(value);
  }

  const handleRowSelection = (rows) => 
  {
    setSelectedItems(rows);
  }
  
  const handleEditCellChange = ({ id, field, props }) => 
  {
    const updatedRows = rows.map((row) => 
    {
      if (row.InventoryNumber === id) 
      {
        return { ...row, [field]: props.value };
      }

      return row;
    });
  
    setRows(updatedRows);
  }

  const handleInputChange = async (event) => 
  {
    let userInput = event.target.value;
    userInput = userInput.trim();

    if (!userInput)
    {
      setPage(0);
      setToggleSearch(!toggleSearch);
    }

    setSearchInput(userInput);
  }

  const handleSearch = () =>
  {
    setPage(0);
    setToggleSearch(!toggleSearch);
  }

  const handleIncrementQuantity = (params, event) => 
  {
    event.stopPropagation();

    setRows((currentRows) => 
    {
      return currentRows.map((row) => 
      {
        if (row.InventoryNumber === params.id) 
        {
          return { ...row, publishedQuantity: row.publishedQuantity + 1 };
        } 
        else 
        {
          return row;
        }
      });
    });
    setFilteredRows((currentRows) => 
    {
      return currentRows.map((row) => 
      {
        if (row.InventoryNumber === params.id) 
        {
          return { ...row, publishedQuantity: row.publishedQuantity + 1 };
        } 
        else 
        {
          return row;
        }
      });
    });
  }

  const handleDecrementQuantity = (params, event) => 
  {
    event.stopPropagation();

    setRows((currentRows) => 
    {
      return currentRows.map((row) => 
      {
        if (row.InventoryNumber === params.id && row.publishedQuantity > 0) 
        {
          return { ...row, publishedQuantity: row.publishedQuantity - 1 };
        } 
        else 
        {
          return row;
        }
      });
    });
    setFilteredRows((currentRows) => 
    {
      return currentRows.map((row) => 
      {
        if (row.InventoryNumber === params.id && row.publishedQuantity > 0) 
        {
          return { ...row, publishedQuantity: row.publishedQuantity - 1 };
        } 
        else 
        {
          return row;
        }
      });
    });
  }

  const handleChangeQuantity = (params, event) => 
  {
    event.stopPropagation();

    const value = event.target.value;
    let newQuantity = parseInt(value, 10);

    if (value === "") 
    {
      newQuantity = 0;
    }

    if (isNaN(newQuantity) || newQuantity < 0) return; // Ensure valid and non-negative input

    setRows((currentRows) => 
    {
      return currentRows.map((row) => 
      {
        if (row.InventoryNumber === params.id) 
        {
          return { ...row, publishedQuantity: newQuantity };
        } 
        else 
        {
          return row;
        }
      });
    });
    setFilteredRows((currentRows) => 
    {
      return currentRows.map((row) => 
      {
        if (row.InventoryNumber === params.id) 
        {
          return { ...row, publishedQuantity: newQuantity };
        } 
        else 
        {
          return row;
        }
      });
    });
  }

  const handleCreateItem = () => 
  {
    navigate("/admin/item-listing/create-item");
  }

  const handlePublishItem = async () => 
  {
    if (selectedItems.length === 0) 
    {
      setItemDeletionType(0);
      setShowModal(true);
    } 
    else 
    {
      const itemIds = [];
      const bidChoices = [];
      const publishedQuantities = [];
      let flag = true;

      selectedItems.forEach((selectedIndex) => 
      {
        const selectedRow = rows.find((row) => row.InventoryNumber === selectedIndex);

        if (selectedRow.BidChoice !== undefined) 
        {
          const minimumBuyingQuantity = selectedRow.Quantity;
          const remainingQuantity = selectedRow.RemainingQuantity;
          const isPublished = selectedRow.published;
          const publishedQuantity = Number(selectedRow.publishedQuantity);

          if (isPublished === "Published") 
          {
            if (publishedQuantity > 0 && publishedQuantity <= remainingQuantity) 
            {
              itemIds.push(selectedRow.InventoryNumber);
              bidChoices.push(selectedRow.BidChoice);
              publishedQuantities.push(selectedRow.publishedQuantity);
            } 
            else if (publishedQuantity === undefined || publishedQuantity <= 0) 
            {
              toast.error("Select a valid quantity to publish");
              flag = false;
            } 
            else if (publishedQuantity > remainingQuantity) 
            {
              toast.error("Published Quantity should be less than or equal to remaining quantity");
              flag = false;
            }
          } 
          else if (isPublished === "Unpublished") 
          {
            if (publishedQuantity === undefined) 
            {
              toast.error("Select a quantity to publish.");
              flag = false;
            } 
            else if (publishedQuantity < minimumBuyingQuantity) 
            {
              toast.error("Published Quantity should be more than Minimum Buying Quantity.");
              flag = false;
            } 
            else if (publishedQuantity > remainingQuantity) 
            {
              toast.error("Published Quantity should be less than or equal to remaining quantity.");
              flag = false;
            } 
            else 
            {
              itemIds.push(selectedRow.InventoryNumber);
              bidChoices.push(selectedRow.BidChoice);
              publishedQuantities.push(selectedRow.publishedQuantity);
            }
          }
        } 
        else 
        {
          toast.error("Select a bid choice for the item before publishing!");
          flag = false;
        }
      });

      if (itemIds.length === 0) 
      {
        return;
      }
      
      if (flag === true) 
      {
        setIsLoading(true);
      
        await axios
        .post("/publish_items", {
            item_ids: itemIds,
            bidChoices: bidChoices,
            publishedQuantities: publishedQuantities,
            sellerId: currentUser?.id
        })
        .then((response) => 
        {
          setIsLoading(false);
          const { status } = response;

          if (status === 200)
          {
            navigate("/admin/item-listing/published-products");
          }
          else
          {
            toast.error("Something went wrong. Please try again later.");
          }
        })
        .catch((error) => 
        {
          setIsLoading(false);

          if (error.response && error.response.data && error.response.data.error) 
          {
            toast.error(error.response.data.error);
          } 
          else 
          {
            toast.error("An unexpected error occurred.");
          }
        });
      }
    }
  }

  const handleDeleteSingleItemWrapper = async (id, name) => 
  {
    setItemToDeleteId(id);
    setTxModalType(1);
    setTxTitle(`Deletion Confirmation`);
    setTxMsg(`Are you sure you want to delete ${name}?`);
    setShowTxModal(true);
  }
  
  const handleDeleteSingleItem = async () =>
  {
    setIsLoading(true);
    setShowTxModal(false);

    await axios
    .put("/delete_inventory_items", { items: [itemToDeleteId] })
    .then((response) => 
    {
      setIsLoading(false);

      if (response.status === 200) 
      {
        toast.success(response?.data?.message || "");

        const updatedRows = rows.filter(row => row.InventoryNumber !== itemToDeleteId) || [];
        const updatedFilteredRows = filteredRows.filter(row => row.InventoryNumber !== itemToDeleteId) || [];

        setRows(updatedRows);
        setFilteredRows(updatedFilteredRows);
        setItemToDeleteId(null);
      }
      else if (response.status === 202)
      {
        toast.error(response?.data?.message || "");
      }
      else
      {
        toast.error("Something went wrong. Please try again later.");
      }
    })
    .catch((error) => 
    {
      console.log("Delete Items: ", error);
      setIsLoading(false);
      toast.error("Something went wrong. Please try again later.");
    });
  }
  
  const handleDeleteMultipleItemsWrapper = () => 
  {
    if (selectedItems.length === 0) 
    {
      setItemDeletionType(1);
      setShowModal(true);
    } 
    else 
    {
      setItemToDeleteId(null);
      setTxModalType(1);
      setTxTitle(`Deletion Confirmation`);
      setTxMsg(`Are you sure you want to delete the selected items?`);
      setShowTxModal(true);
    }
  }

  const handleDeleteMultipleItems = async () =>
  {
    setIsLoading(true);
    setShowTxModal(false);

    await axios
    .put("/delete_inventory_items", { items: selectedItems })
    .then((response) => 
    {
      setIsLoading(false);

      if (response.status === 200) 
      {
        toast.success(response?.data?.message || "");
        
        const updatedRows = rows.filter(row => !selectedItems.includes(row.InventoryNumber)) || [];
        const updatedFilteredRows = filteredRows.filter(row => !selectedItems.includes(row.InventoryNumber)) || [];

        setRows(updatedRows);
        setFilteredRows(updatedFilteredRows);
        setSelectedItems([]);
      }
      else if (response.status === 202)
      {
        toast.error(response?.data?.message || "");
      }
      else
      {
        toast.error("Something went wrong. Please try again later.");
      }
    })
    .catch((error) => 
    {
      console.log("Delete Items: ", error);
      setIsLoading(false);
      toast.error("Something went wrong. Please try again later.");
    });
  }

  const handleCloseModal = () => 
  {
    setShowModal(false);
  }

  return (
    <div className = "inventory_list_page">
      {!currentUser ? (
        <Navigate to = "/admin/login" replace = {true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar = {getCollapseSidebar}
              getCollapseDropdown = {getCollapseDropdown}
              logOut = {props.logOut}
            />
          )}

          <div className = "admin_panel">
            <div className = "d-lg-flex">
              <div className = {`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"}`}>
                <Sidebar
                  collapsed = {collapseSidebar}
                  collapsedDropdown = {collapseDropdown}
                />
              </div>

              <div className = {`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"}`}>
                {!matches && (
                  <Header
                    getCollapseSidebar = {getCollapseSidebar}
                    getCollapseDropdown = {getCollapseDropdown}
                    logOut = {props.logOut}
                  />
                )}

                {props.message && (
                  <ToastContainer className = "messageToast">
                    <Toast
                      delay = {3000}
                      autohide
                      onClose = {() => props.setMessage(null)}
                    >
                      <Toast.Header>
                        <strong className = "me-auto">Message</strong>
                        <small>2 secs ago</small>
                      </Toast.Header>
                      <Toast.Body>{props.message}</Toast.Body>
                    </Toast>
                  </ToastContainer>
                )}

                <div className = {`w-100 content_panel ${collapseSidebar ? "slide-in" : "slide-out"}`}>
                  <div style = {{ height: '100vh' }}>
                    <h3 className = "pageName_heading">
                      {localStorage.getItem("logoUrl") && (
                        <img
                          src = {localStorage.getItem("logoUrl")}
                          alt = "Company Logo"
                          style = {{
                            width: '40px',
                            height: '40px',
                            marginRight: '5px',
                            verticalAlign: 'middle'
                          }}
                        />
                      )}
                      Inventory List
                    </h3>

                    <div className = "listing row">
                      <div className="col-12 col-md-6 h-[45px] position-relative">
                        <div className="admin_panel_searchbar">
                          <input
                            type = "text"
                            placeholder = "Search..."
                            className = "input py-[1.5em] px-[3em]"
                            onChange = {(event) => handleInputChange(event)}
                          />

                          <button
                            className = "search"
                            type = "button"
                            onClick = {handleSearch}
                          >
                            <IoIosSearch
                              style = {{ color: "black", fontSize: "18px" }}
                            />
                          </button>

                          {/* <ul
                            className = "dropdown-menu"
                            aria-labelledby = "filterButton"
                            style = {{ zIndex: "1", fontSize: "14px" }}
                          >
                            <li>
                              <a
                                className = "dropdown-item"
                                href = "#/"
                                onClick = {(event) => handleDropdownClick(event, "Name")}
                              >
                                Filter by Name
                              </a>
                            </li>
                            <li>
                              <a
                                className = "dropdown-item"
                                href = "#/"
                                onClick = {(event) => handleDropdownClick(event, "Description")}
                              >
                                Filter by Description
                              </a>
                            </li>
                            <li>
                              <a
                                className = "dropdown-item"
                                href = "#/"
                                onClick = {(event) => handleDropdownClick(event, "Price")}
                              >
                                Filter by Price
                              </a>
                            </li>
                          </ul> */}
                        </div>
                      </div>

                      <div className = "col-12 col-md-6">
                        <div className = "admin_couple_buttons">
                          <div className = "text-center">
                            <button
                              type = "button"
                              className = "createItemButton"
                              onClick = {handleCreateItem}
                            >
                              Create Item
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className = "customCard" style = {styles.container}>
                      <GridContextProvider>
                        <DataGrid
                          loading = {isLoading}
                          columns = {columns}
                          rows = {filteredRows}
                          getRowId = {(row) => row.InventoryNumber}
                          rowCount = {totalPages}
                          rowHeight = {40}
                          pageSize = {pageSize}
                          rowsPerPageOptions = {[]}
                          pagination
                          paginationMode = "server"
                          density = "compact"
                          disableExtendRowFullWidth = {true}
                          disableSelectionOnClick = {false}
                          checkboxSelection = {!isLoading}
                          selectionModel = {selectedItems}
                          onSelectionModelChange = {handleRowSelection}
                          onCellEditCommit = {handleEditCellChange}
                          onPageChange = {(newPage) => setPage(newPage)}
                          getRowClassName = {(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                              ? "dataTable_even_row"
                              : "dataTable_odd_row"
                          }
                          components = {{
                            NoRowsOverlay: () => (
                              <Stack
                                height = "100%"
                                alignItems = "center"
                                justifyContent = "center"
                              >
                                There are no records to display.
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack
                                height = "100%"
                                alignItems = "center"
                                justifyContent = "center"
                              >
                                There are no records to display.
                              </Stack>
                            )
                          }}
                        />
                      </GridContextProvider>
                    </div>

                    {filteredRows && filteredRows.length > 0 && (
                      <>
                        <CustomDownloader
                          title = "Inventory List"
                          rows = {filteredRows}
                        />

                        <button
                          type = "button"
                          className = "publish ml-5"
                          onClick = {handlePublishItem}
                        >
                          Publish Item(s)
                        </button>

                        <button
                          type = "button"
                          className = "publish"
                          onClick = {handleDeleteMultipleItemsWrapper}
                        >
                          Delete Item(s)
                        </button>
                      </>
                    )}

                    <br />

                    <Modal centered show = {showModal} onHide = {handleCloseModal}>
                      <Modal.Body>
                        <h1 className = "text-slate-600 text-[18px] text-center p-[1em]">
                          Please select one or more items from the table before
                          {itemDeletionType === 1 ? " deleting" : " publishing"}
                        </h1>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant = "warning"
                          onClick = {handleCloseModal}
                          style = {{
                            margin: 'auto',
                            width: '100px',
                            color: 'white'
                          }}
                        >
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <TxModal
        show = {showTxModal}
        title = {txTitle}
        type = {txModalType}
        msg = {txMsg}
        login = {false}
        setShow = {setShowTxModal}
        confirmTx = {itemToDeleteId ? handleDeleteSingleItem : handleDeleteMultipleItems}
      />
    </div>
  );
}

export default InventoryList;