import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PopUp from '../home/PopUp';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import TxModal from '../admin/TxModal';
import BoxImage from '../../images/3.png';
import axios from 'axios';
import '../../css/style.css';
import '../../css/search.css';

const SearchProduct = (props) => 
{
  const navigate = useNavigate();
  const location = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  /* API VARIABLES */
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingToWishList, setIsAddingToWishList] = useState(false);
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);

  /* LIST VARIABLES */
  const [items, setItems] = useState([]);
  const [lots, setLots] = useState([]);
  
  /* PRODUCT VARIABLES */
  const [searchProduct, setSearchProduct] = useState("");
  const [product, setProduct] = useState({});
  const [productCount, setProductCount] = useState(0);

  /* PRODUCT CATEGORY VARIABLES */
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  /* RATING VARIABLES */
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");

  /* PAGINATION VARIABLES */
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  /* TAB VARIABLES */
  const [selectedTab, setSelectedTab] = useState("All");

  /* CUSTOM MODAL VARIABLES */
  const [showTxModal, setShowTxModal] = useState(false);
  const [txModalType, setTxModalType] = useState();
  const [txTitle, setTxTitle] = useState("");
  const [txMessage, setTxMessage] = useState("");

  /* POP-UP VARIABLES */
  const [showPopUp, setShowPopUp] = useState(false);

  /* TOAST VARIABLES */
  const [showAddedToWishListToast, setShowAddedToWishListToast] = useState(false);

  useEffect(() => 
  {
    const pathname = location?.pathname || "";

    if (pathname) 
    {
      const category = pathname?.split("/")?.[2]?.replace(/%20/gi, " ") || "All Categories";
      setSelectedCategory(category);
    } 
    else 
    {
      setSelectedCategory("All Categories");
    }
  }, [location]);

  useEffect(() => 
  {
    const searchProducts = async () =>
    {
      setIsLoading(true);

      const limit = selectedTab === "All" ? pageSize / 2 : pageSize;
      const offset = page > 1 ? (page - 1) * limit : 0;

      await axios.post("/product_search", {
        category: selectedCategory,
        name: searchProduct,
        limit: limit,
        offset: offset
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data = {} } = response;

        if (status === 200)
        {
          const { items = [], lots = [], item_count: itemCount = 0, lot_count: lotCount = 0 } = data || {};
          const count = selectedTab === "All"
            ? parseInt(itemCount) + parseInt(lotCount)
            : selectedTab === "Items"
              ? parseInt(itemCount)
              : selectedTab === "Lots"
                ? parseInt(lotCount)
                : 0;
          const pages = Math.ceil(count / pageSize);

          setItems(items);
          setLots(lots);
          setProductCount(count);
          setTotalPages(pages);
        }
        else
        {
          toast.error("There was an error while retrieving the products. Please try again later or contact the support team for further assistance.");
        }
      })
      .catch((error) => 
      {
        console.log("Search Product Api: ", error);
        setIsLoading(false);
        toast.error("There was an error while retrieving the products. Please try again later or contact the support team for further assistance.");
      });
    }
  
    if (selectedCategory || searchProduct) 
    {
      searchProducts();
    }
  }, [selectedCategory, searchProduct, selectedTab, pageSize, page]);
  
  useEffect(() => 
  {
    const fetchCategories = async () =>
    {
      axios.get("/get_categories")
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data = [] } = response;

        if (status === 200)
        {
          const categories = data.map((category) => category.categoryName) || [];
          setCategories(categories);
        }
      })
      .catch((error) => 
      {
        console.log("Get Categories Api: ", error);
      });
    }

    fetchCategories();
  }, []);

  const isValidImage = (image) => 
  {
    const imageName = image?.split("?")?.[0]?.split("/")?.at(-1) || null;
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
    
    return imageName && typeof imageName === 'string' && imageExtensions.test(imageName);
  }

  const handleInputChange = (event) => 
  {
    setSelectedCategory(event.target.value);
  }

  const handleTabChange = (tab) =>
  {
    setSelectedTab(tab);
    setPage(1);
    setTotalPages(0);
    setProductCount(0);
  }

  const handlePageChange = (_, value) => 
  {
    setPage(value);
  }

  const handleSearchProduct = (event) => 
  {
    setSearchProduct(event.target.value);
  }

  const handleSelectProduct = (product, type) => 
  {
    const newProduct = { ...product };

    if (!product["Seller Name"]) 
    {
      newProduct["Seller Name"] = "*****";
      setProduct(newProduct);
    }

    window.scrollTo(0, 0);
    const id = type === "product" ? newProduct.itemId : newProduct.lotId;
    navigate(`/product-detail/${type}/${id}`, { state: { newProduct, type } })
  }

  const handleAddToWishList = async (id, price, type) => 
  {
    if (!currentUser) 
    {
      setIsLoggedIn(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMessage(`Login before adding to wishlist`);
      setShowTxModal(true);
    } 
    else 
    {      
      if (type === "product") 
      {
        setIsAddingToWishList(true);

        const item = {
          userId: currentUser.id,
          price: price,
          quantity: 1,
          itemId: id,
          type: type
        };

        await axios.post("/add_to_wishlist", item)
        .then((response) => 
        {
          setIsAddingToWishList(false);
          const { status } = response;

          if (status === 200) 
          {
            setShowAddedToWishListToast(true);
          }
          else if (status === 202) 
          {
            toast.error("The requested quantity exceeds available stock.");
          }
        })
        .catch((error) => 
        {
          console.log("Add Item to WishList Api: ", error);
          setIsAddingToWishList(false);
          toast.error("There was an error while adding item to wishlist. Please try again later or contact the support team for further assistance.");
        });
      }
      else if (type === "lot") 
      {
        setIsAddingToWishList(true);

        const lot = {
          userId: currentUser.id,
          price: price,
          quantity: 1,
          lotId: id,
          type: type
        };

        await axios.post("/add_to_wishlist", lot)
        .then((response) => 
        {
          setIsAddingToWishList(false);
          const { status } = response;

          if (status === 200) 
          {
            setShowAddedToWishListToast(true);
          }
          else if (status === 202) 
          {
            toast.error("The selected lot already exists in the wishlist.");
          }
        })
        .catch((error) => 
        {
          console.log("Add Lot to WishList Api: ", error);
          setIsAddingToWishList(false);
          toast.error("There was an error while adding item to wishlist. Please try again later or contact the support team for further assistance.");
        });
      }
    }
  }
  
  const handleSubmitRequest = async (event) => 
  {
    event.preventDefault();
    setIsSubmittingRequest(true);

    const itemName = event.target.itemName.value || "";
    const itemQuantity = event.target.itemQuantity.value || 0;

    await axios.post("/send_request_mail", {
      itemName,
      itemQuantity
    })
    .then((response) =>
    {
      setIsSubmittingRequest(false);
      const { status } = response;

      if (status === 200)
      {
        toast.success("Your request has been submitted successfully!");
        handleClosePopUp();
      }
      else
      {
        toast.error("There was an error while submitting your request. Please try again later or contact the support team for further assistance.");
      }
    })
    .catch((error) =>
    {
      console.log("Send Request Api: ", error);
      setIsSubmittingRequest(false);
      toast.error("There was an error while submitting your request. Please try again later or contact the support team for further assistance.");
    });
  }
  
  const handleOpenPopUp = (event) =>
  {
    event?.preventDefault();
    setShowPopUp(true);
  }

  const handleOpenCustomModal = () => 
  {
    if (isLoggedIn === true) 
    {
      axios.post("/clear_cart", { 
        userId: currentUser.id 
      })
      .then((response) => 
      {
        const { status } = response;

        if (status === 200)
        {
          setShowTxModal(false);
        }
      })
      .catch((error) => 
      {
        console.log("Clear Cart Api: ", error);
      });
    } 
    else 
    {
      navigate("/admin/login");
    }
  }  

  const handleCloseModal = () =>
  {
    setShowRatingModal(false);
  }

  const handleClosePopUp = () => 
  {
    setShowPopUp(false);
  }

  const handleCloseToast = () => 
  {
    setShowAddedToWishListToast(false);
  }
  
  return (
    <div className = "search-product">
      <Header logOut = {props.logOut} />

      <Card className = "sp-1 height-50">
        <Row className = "app_hero height-50">
          <Col xs = {12} md = {7} className = "height-50">
            <Row className = "search_field_row">
              <Col
                xs = {12}
                className = "bg-1-sub-2 p-0 search_page_header"
              >
                <div className = "search-bar">
                  <div className = "form-group w-100">
                    <input
                      type = "text"
                      className = "form-control searchPageInput"
                      placeholder = "Search"
                      value = {searchProduct}
                      onChange = {handleSearchProduct}
                    />
                  </div>

                  <div className = "selectContainer">
                    <select
                      className = "form-control searchPageDropdown"
                      value = {selectedCategory}
                      onChange = {handleInputChange}
                    >
                      {categories.map((value, index) => (
                        <option key = {index} value = {value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <a
                  href = "/#"
                  className = "popup-link"
                  onClick = {handleOpenPopUp}
                >
                  Couldn't find what you were looking for? Ask sellers.
                </a>
              </Col>
            </Row>

            <Row className = "sp-1-5 product-count-container">
              <Col xs = {12} className = "sp-1-sub-5">
                {productCount} items found under{" "}
                <span>{selectedCategory}</span>
              </Col>
            </Row>
          </Col>

          <Col xs = {6} md = {3} className = "height-50 image-container">
            <img
              className = "sp-1-6"
              src = {BoxImage}
              alt = ""
            />
          </Col>
        </Row>
      </Card>

      <div className = "app_container container">
        <div className = "d-flex justify-content-center">
          <div className = "btn-group" role = "group">
            <button
              type = "button"
              className = {`btn custom_btn ${selectedTab === "All" ? "btn-primary" : "btn-light"}`}
              onClick = {() => handleTabChange("All")}
            >
              All
            </button>

            <button
              type = "button"
              className = {`btn custom_btn ${selectedTab === "Items" ? "btn-primary" : "btn-light"}`}
              onClick = {() => handleTabChange("Items")}
            >
              Items
            </button>

            <button
              type = "button"
              className = {`btn custom_btn ${selectedTab === "Lots" ? "btn-primary" : "btn-light"}`}
              onClick = {() => handleTabChange("Lots")}
            >
              Lots
            </button>
          </div>
        </div>
        <br />

        {isLoading ? (
          <div className = "d-flex justify-content-center">
            <div className = "spinner-border text-info" role = "status"></div>
          </div>
        ) : (
          <div className = "d-flex flex-column gap-4">
            {selectedTab === "All" ? (
              <div className = "row products_row">
                {items?.map((item, index) => (
                  <div
                    key = {index}
                    className = "col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center"
                  >
                    <div className = "product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                      <figure
                        className = "figure-container"
                        onClick = {() => handleSelectProduct(item, "product")}
                      >
                        <img
                          loading = "lazy"
                          src = {`${isValidImage(item?.itemImage) ? item?.itemImage : require("../../images/no-image.jpg")}`}
                          alt = ""
                        />
                      </figure>

                      <div className = "card-body">
                        <div className = "rating rating-sm space-x-2">
                          {[...Array(5)].map((_, i) => {
                            const ratingValue = i + 1;

                            return (
                              <input
                                key = {i}
                                className = {`cursor-default ${item?.averageRating >= ratingValue ? "mask mask-star-2 bg-orange-400" : "mask mask-star-2"}`}
                                type = "radio"
                                name = {`rating-${index}`}
                                disabled
                              />
                            );
                          })}
                        </div>

                        <div className = "flex flex-row justify-content-between">
                          <h2 className = "text-[14px] mt-1">{item?.itemName}</h2>

                          <div className = "rating w-30px float-right">
                            {isAddingToWishList ? (
                              <div className = "spinner-border text-info" role = "status"></div>
                            ) : (
                              <input
                                type = "radio"
                                name = {`rating-${index}`}
                                className = "whishlist_icon hover:bg-red-600"
                                onClick = {() => handleAddToWishList(item?.itemId, item?.itemPrice, "product")}
                              />
                            )}
                          </div>
                        </div>

                        <hr className = "m-0" />
                      </div>

                      <div className = "product_price px-3 py-2">
                        <p className = "text-[14px]">{item?.itemDesc}</p>
                        <br />
                        <h1 className = "text-[20px] font-bold mb-3 price">
                          USD {item?.itemPrice}
                        </h1>
                      </div>
                    </div>
                  </div>
                ))}

                {lots?.map((lot, index) => (
                  <div
                    key = {index}
                    className = "col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center"
                  >
                    <div className = "product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                      <figure
                        className = "figure-container"
                        onClick = {() => handleSelectProduct(lot, "lot")}
                      >
                        <img
                          loading = "lazy"
                          src = {`${lot?.lotImage}`}
                          alt = ""
                        />
                      </figure>

                      <div className = "card-body">
                        <div className = "rating rating-sm space-x-2">
                          {[...Array(5)].map((_, i) => {
                            const ratingValue = i + 1;

                            return (
                              <input
                                key = {i}
                                type = "radio"
                                className = {`cursor-default ${lot?.averageRating >= ratingValue ? "mask mask-star-2 bg-orange-400" : "mask mask-star-2"}`}
                                name = {`rating-${index}`}
                                disabled
                              />
                            );
                          })}
                        </div>

                        <div className = "flex flex-row justify-content-between">
                          <h2 className = "text-[14px] mt-1">{lot?.lotName}</h2>
                          
                          <div className = "rating w-30px float-right">
                            {isAddingToWishList ? (
                              <div className = "spinner-border text-info" role = "status"></div>
                            ) : (
                              <input
                                type = "radio"
                                name = {`rating-${index}`}
                                className = "whishlist_icon hover:bg-red-600"
                                onClick = {() => handleAddToWishList(lot?.lotId, lot?.lotPrice, "lot")}
                              />
                            )}
                          </div>
                        </div>

                        <hr className = "m-0" />
                      </div>

                      <div className = "product_price px-3 py-2">
                        <p className = "text-[14px]">{lot?.lotDesc}</p>
                        <br />
                        <h1 className = "text-[20px] font-bold mb-3 price">
                          USD {lot?.lotPrice}
                        </h1>
                      </div>
                    </div>
                  </div>
                ))}

                {(items?.length === 0 && lots?.length === 0) && (
                  <span>No products found.</span>
                )}
              </div>
            ) : selectedTab === "Items" ? (
              <div className = "row products_row"> 
                {items?.map((item, index) => (
                  <div
                    key = {index}
                    className = "col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center"
                  >
                    <div className = "product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                      <figure
                        className = "figure-container"
                        onClick = {() => handleSelectProduct(item, "product")}
                      >
                        <img
                          loading = "lazy"
                          src = {`${isValidImage(item?.itemImage) ? item?.itemImage : require("../../images/no-image.jpg")}`}
                          alt = ""
                        />
                      </figure>

                      <div className = "card-body">
                        <div className = "rating rating-sm space-x-2">
                          {[...Array(5)].map((_, i) => {
                            const ratingValue = i + 1;

                            return (
                              <input
                                key = {i}
                                className = {`cursor-default ${item?.averageRating >= ratingValue ? "mask mask-star-2 bg-orange-400" : "mask mask-star-2"}`}
                                type = "radio"
                                name = {`rating-${index}`}
                                disabled
                              />
                            );
                          })}
                        </div>

                        <div className = "flex flex-row justify-content-between">
                          <h2 className = "text-[14px] mt-1">{item?.itemName}</h2>

                          <div className = "rating w-30px float-right">
                            {isAddingToWishList ? (
                              <div className = "spinner-border text-info" role = "status"></div>
                            ) : (
                              <input
                                type = "radio"
                                name = {`rating-${index}`}
                                className = "whishlist_icon hover:bg-red-600"
                                onClick = {() => handleAddToWishList(item?.itemId, item?.itemPrice, "product")}
                              />
                            )}
                          </div>
                        </div>

                        <hr className = "m-0" />
                      </div>

                      <div className = "product_price px-3 py-2">
                        <p className = "text-[14px]">{item?.itemDesc}</p>
                        <br />
                        <h1 className = "text-[20px] font-bold mb-3 price">
                          USD {item?.itemPrice}
                        </h1>
                      </div>
                    </div>
                  </div>
                ))}

                {(items?.length === 0) && (
                  <span>No items found.</span>
                )}
              </div>
            ) : selectedTab === "Lots" && (
              <div className="row products_row">
                {lots?.map((lot, index) => (
                  <div
                    key = {index}
                    className = "col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center"
                  >
                    <div className = "product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                      <figure
                        className = "figure-container"
                        onClick = {() => handleSelectProduct(lot, "lot")}
                      >
                        <img
                          loading = "lazy"
                          src = {`${lot?.lotImage}`}
                          alt = ""
                        />
                      </figure>

                      <div className = "card-body">
                        <div className = "rating rating-sm space-x-2">
                          {[...Array(5)].map((_, i) => {
                            const ratingValue = i + 1;

                            return (
                              <input
                                key = {i}
                                type = "radio"
                                className = {`cursor-default ${lot?.averageRating >= ratingValue ? "mask mask-star-2 bg-orange-400" : "mask mask-star-2"}`}
                                name = {`rating-${index}`}
                                disabled
                              />
                            );
                          })}
                        </div>

                        <div className = "flex flex-row justify-content-between">
                          <h2 className = "text-[14px] mt-1">{lot?.lotName}</h2>
                          
                          <div className = "rating w-30px float-right">
                            {isAddingToWishList ? (
                              <div className = "spinner-border text-info" role = "status"></div>
                            ) : (
                              <input
                                type = "radio"
                                name = {`rating-${index}`}
                                className = "whishlist_icon hover:bg-red-600"
                                onClick = {() => handleAddToWishList(lot?.lotId, lot?.lotPrice, "lot")}
                              />
                            )}
                          </div>
                        </div>

                        <hr className = "m-0" />
                      </div>

                      <div className = "product_price px-3 py-2">
                        <p className = "text-[14px]">{lot?.lotDesc}</p>
                        <br />
                        <h1 className = "text-[20px] font-bold mb-3 price">
                          USD {lot?.lotPrice}
                        </h1>
                      </div>
                    </div>
                  </div>
                ))}

                {(lots?.length === 0) && (
                  <span>No lots found.</span>
                )}
              </div>
            )}

            {productCount > 0 && (
              <Stack className = "m-auto" spacing = {2}>
                <Pagination 
                  count = {totalPages} 
                  page = {page}
                  variant = "outlined" 
                  shape = "rounded" 
                  onChange = {handlePageChange}
                />
              </Stack>
            )}
          </div>
        )}

        <Footer />
      </div>

      <Modal
        show = {showRatingModal}
        onHide = {handleCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Rate{" "}
            {product?.itemName ? product.itemName : product?.lotName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className = "star-rating">
            {[...Array(5)].map((_, i) => {
              const ratingValue = i + 1;

              return (
                <label key = {i}>
                  <input
                    type = "radio"
                    name = "rating"
                    value = {ratingValue || 0}
                    hidden
                    onClick = {() => setRating(ratingValue)}
                  />
                  <span className = {rating >= ratingValue ? "star filled" : "star"}>
                    &#9733;
                  </span>
                </label>
              );
            })}
          </div>

          <textarea
            className = "form-control mt-2"
            placeholder = "Enter Feedback"
            value = {feedback || ""}
            onChange = {(event) => setFeedback(event.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant = "secondary" onClick = {handleCloseModal}>
            Close
          </Button>
          <Button variant = "primary" onClick = {handleCloseModal}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <TxModal
        show = {showTxModal}
        type = {txModalType}
        title = {txTitle}
        msg = {txMessage}
        login = {true}
        confirmTx = {handleOpenCustomModal}
        setShow = {setShowTxModal}
      />

      <PopUp
        showPopUp = {showPopUp}
        isSubmittingRequest = {isSubmittingRequest}
        handleSubmitRequest = {handleSubmitRequest}
        handleClosePopUp = {handleClosePopUp}
      />

      <ToastContainer className = "showToast">
        <Toast
          show = {showAddedToWishListToast}
          autohide = {true}
          onClose = {handleCloseToast}
        >
          <Toast.Header>
            <strong className = "me-auto">WishList</strong>
            <small>1 second ago</small>
          </Toast.Header>
          <Toast.Body onClick = {() => navigate("/negotiate-cart")}>
            Product added to wishlist.
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default SearchProduct;