// ProductRow.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TxModal from "../admin/TxModal";
import {
  Toast,
  ToastContainer as ToastContainerBootstrap,
} from "react-bootstrap";

function ProductCard({ product, handleClick, addToWishList, favourites }) {
  const isValidImage = (image) => {
    const imageName = image?.split("?")?.[0]?.split("/")?.at(-1) || null;
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;

    return imageName && typeof imageName === 'string' && imageExtensions.test(imageName);
  }

  return (
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
      <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
        <figure style={{ margin: "0", height: "200px", cursor: "pointer" }} onClick={() => handleClick(product, 'product')}>
          <img loading="lazy"
            src={`${isValidImage(product?.itemImage) ? product?.itemImage : require("../../images/no-image.jpg")}`}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </figure>
        <div className="card-body">
          <div className="rating rating-sm space-x-2">
            {/* Update your rating inputs here */}
          </div>
          <div className="flex flex-row" style={{ justifyContent: "space-between" }}>
            <h2 className="text-[14px] mt-1">{product.itemName}</h2>
            <div className="rating w-30px" style={{ float: "right" }}>
              <input
                type="radio"
                name="rating-3"
                className="whishlist_icon bg-slate-200 hover:bg-red-600"
                style={{ fontWeight: `${favourites.indexOf(product.itemName) > -1 ? "900" : ""}` }}
                onClick={() => addToWishList(product?.itemId, product.itemPrice, 'product')}
              />
            </div>
          </div>
          <hr style={{ margin: "0" }} />
        </div>
        <div className="product_price" style={{ padding: "0 1em" }}>
          <p className="text-[14px]">{product.itemDesc}</p>
          <br />
          <h1 className="text-[20px] font-bold mb-3" style={{ color: "#0d6efd" }}>USD {product.itemPrice}</h1>
        </div>
      </div>
    </div>
  );
}


function LiveItems() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [favourites, setFavourites] = useState([]);
  const [showTxModal, setShowTxModal] = useState(false);
  const [txModalType, setTxModalType] = useState();
  const [txTitle, setTxTitle] = useState("");
  const [txMsg, setTxMsg] = useState("");
  const [txData, setTxData] = useState();
  const [showAddedToWishListToast, setShowAddedToWishListToast] =
    useState(false);
  const [loginFlag, setLoginFlag] = useState(true);
  const [product, setProduct] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));

  // Fetch products from your API
  useEffect(() => {
    if (currentUser) {
      axios
        .post("/related_product_search", {
          userId: currentUser.id
        })
        .then((response) => {
          setProducts(response.data.related_items);
          console.log('PRODUCTS', response.data);
        })
        .catch((error) => console.log(error));

    }
    else {
      axios.post("/related_product_search", {
        userId: -1
      })
        .then((response) => {
          setProducts(response.data.related_items);
          console.log('PRODUCTS', response.data);
        })
        .catch((error) => console.log(error));

    }

  }, []);
  function handleClick(product, type) {
    console.log("TYPE PAGE", type)

    if (!product["Seller Name"]) {
      const newProd = product;
      newProd["Seller Name"] = "*****";
      setProduct(newProd);
    }
    window.scrollTo(0, 0);  // Reset scroll position
    const id = type === "product" ? product.itemId : product.lotId;
    navigate(`/product-detail/${type}/${id}`, { state: { product, type } })
  }
  const addToWishList = (id, price, type) => {
    if (!currentUser) {
      setLoginFlag(false);
      setTxModalType(1);
      setTxTitle(`Login Confirmation`);
      setTxMsg(`Login before adding to wishlist`);
      //setTxData(tableMeta.rowData[0]);
      setShowTxModal(true);
    } else {
      if (type === "product") {
        const objToSend = {
          userId: currentUser.id,
          price: price,
          quantity: 1,
          itemId: id,
          type: type
        };
        axios
          .post("/add_to_wishlist", objToSend)
          .then((response) => {
            if (response.status === 202) {
              toast.error("Quantity exceeds the available quantity");
            } else if (response.status === 200) {
              setShowAddedToWishListToast(true);
            }
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });


      }
      else if (type === "lot") {
        const objToSend = {
          userId: currentUser.id,
          price: price,
          quantity: 1,
          lotId: id,
          type: type
        };
        axios
          .post("/add_to_wishlist", objToSend)
          .then((response) => {
            if (response.status === 202) {
              toast.error("Lot already exists in the wishlist");
            } else if (response.status === 200) {
              setShowAddedToWishListToast(true);
            }
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });

      }
    }
  };
  const reveal = async () => {
    if (loginFlag === true) {
      axios
        .post("/clear_cart", { userId: currentUser.id })
        .then((response) => {
          setShowTxModal(false);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate("/admin/login");
    }
  };
  return (
    <div className='container app_container'>
      <div className="row related_products">
        {products?.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            handleClick={handleClick}
            addToWishList={addToWishList}
            favourites={favourites}
          />
        ))}
        <TxModal
          title={txTitle}
          msg={txMsg}
          show={showTxModal}
          setShow={setShowTxModal}
          confirmTx={reveal}
          type={txModalType}
          login={true}
        />
        <ToastContainerBootstrap className="showToast">
          {/* <Toast
          autohide={true}
          show={showAddedToCartToast}
          onClose={() => setShowAddedToCartToast(false)}
        >
          <Toast.Header>
            <strong className="me-auto">Cart</strong>
            <small>1 second ago</small>
          </Toast.Header>
          <Toast.Body>Product added to cart.</Toast.Body>
        </Toast> */}

          <Toast
            autohide={true}
            show={showAddedToWishListToast}
            onClose={() => setShowAddedToWishListToast(false)}
          >
            <Toast.Header>
              <strong className="me-auto">WishList</strong>
              <small>1 second ago</small>
            </Toast.Header>
            <Toast.Body onClick={() => navigate("/negotiate-cart")}>
              Product added to wishlist.
            </Toast.Body>
          </Toast>
        </ToastContainerBootstrap>
      </div>
    </div>

  );
}

export default LiveItems;